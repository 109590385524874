.plc-song-player__players-row {
	display: grid;
	grid-gap: $plc-gap--l;
	grid-template-columns: 1fr 1fr;
	height: 400px;
}

.plc-song-player__player-wrapper,
.plc-song-viewer__player-wrapper {
	align-content: center;
	border-radius: $plc-border-radius--m;
	box-shadow: 0 0 10px 5px;
	display: grid;
	justify-self: center;
	overflow: hidden;
	position: relative;
	transition: $plc-duration--m;
	transition-property: transform, box-shadow;
}

.plc-song-player__player-wrapper--blue-team,
.plc-song-viewer__player-wrapper--blue-team {
	color: $plc-dark-blue;
}

.plc-song-player__player-wrapper--orange-team,
.plc-song-viewer__player-wrapper--orange-team {
	color: $plc-dark-orange;
}

.plc-song-player__player-wrapper--turn-owner,
.plc-song-viewer__player-wrapper--turn-owner {
	transform: scale(1.01);
}

.plc-song-player__player-wrapper--turn-missed,
.plc-song-viewer__player-wrapper--turn-missed {
	box-shadow: 0 0 0 0;
	transform: scale(0.99);
}

// ============= Small Screen styles =============
@media screen and (max-width: $plc-small-screen-width) {
	.plc-song-player__players-row {
		grid-template-columns: 1fr !important;
		grid-template-rows: 1fr 1fr;
		height: auto !important;
	}

	.plc-song-player__player-wrapper,
	.plc-song-viewer__player-wrapper {
		height: 200px;
	}
}

// Breakpoints

/** @deprecated */
$plc-breakpoints: (
	'small': (
		max-width: 768px,
	),
	'medium': (
		min-width: 768px,
	),
	'large': (
		min-width: 992px,
	),
	'huge': (
		min-width: 1200px,
	),
);

$plc-small-screen-width: 768px;
$plc-medium-screen-width: 1200px;

//Colors

//Z-index
$plc-menu__options--z-index: 1;

.plc-memory-dashboard__answer-row {
	height: max-content;

	.plc-box__main {
		display: grid;
		grid-template-columns: repeat(3, auto);
		justify-content: space-around;
	}
}

.plc-memory-dashboard__buttons-row {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(4, auto);

	plc-button:nth-child(even) {
		justify-self: end;
	}

	plc-button:first-child {
		margin-right: $plc-gap--l;
	}

	plc-button:last-child {
		margin-left: $plc-gap--xl;
	}
}

.plc-memory-dashboard__toggle-button {
	justify-self: center;
	margin-top: $plc-gap--l;
}

.plc-memory-dashboard__fix-dialog {
	width: 500px;
}

.plc-button {
	align-items: center;
	border-radius: $plc-border-radius--m;
	box-sizing: border-box;
	cursor: pointer;
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--m;
	height: 60px;
	justify-content: center;
	max-width: max-content;
	min-width: 144px;
	outline: none;
	padding: 0 $plc-gap--m;
	transition-duration: $plc-duration--m;
	transition-property: background-color, color, outline-offset;
}

.plc-button--only-icon {
	min-width: 50px;
	width: 60px;
}

.plc-button--only-text {
	min-width: 90px;
}

.plc-button--flattened {
	height: 45px;
}

.plc-button__icon {
	height: 30px;
	width: 30px;
}

.plc-button__text {
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
}

.plc-button--primary {
	background-color: var(--plc-button--primary--bg);
	border: 0;
	color: var(--plc-button--primary--color);
}

.plc-button--primary__icon {
	filter: var(--plc-button--primary__icon--filter);
}

.plc-button--secondary {
	background-color: var(--plc-button--secondary--bg);
	border: 0;
	color: var(--plc-button--secondary--color);
}

.plc-button--secondary__icon {
	filter: var(--plc-button--secondary__icon--filter);
}

.plc-button--outline {
	background-color: transparent;
	border: var(--plc-button--outline--border);
	color: var(--plc-button--outline--color);
}

.plc-button--outline__icon {
	filter: var(--plc-button--outline__icon--filter);
}

.plc-button--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}

.plc-button:hover {
	filter: contrast(130%);
	outline: 2px solid var(--plc-button--outline--color);
	outline-offset: $plc-gap--s;
	transition-duration: $plc-duration--s;
}

.plc-button:active {
	filter: contrast(130%);
	outline-offset: $plc-gap--xs;
	transform: scale(0.9);
	transform-origin: inherit;
}

@include respond-to('small') {
	.plc-button {
		height: 45px;
		width: 120px;
	}

	.plc-button--only-icon {
		width: 45px;
	}

	.plc-button--only-text {
		width: 70px;
	}

	.plc-button--flattened {
		height: 40px;
	}

	.plc-button__content {
		grid-gap: $plc-gap--s;
	}

	.plc-button__text {
		font-size: 0.9rem;
	}

	.plc-button__icon {
		height: 20px;
		width: 20px;
	}
}

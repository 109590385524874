.plc-role-card {
	background-color: var(--plc-role-card--bg);
	border-radius: $plc-border-radius--m;
	color: var(--plc-role-card--color);
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(2, auto);
	padding: $plc-gap--m;
	transition-duration: $plc-duration--l;
	transition-property: background-color, color, background-image;
	width: max-content;
}

.plc-role-card__name {
	align-self: center;
	display: grid;
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--m;
	letter-spacing: -8px;
	text-orientation: upright;
	writing-mode: vertical-lr;
}

.plc-role-card__main {
	align-content: center;
	background-color: var(--plc-role-card__main--bg);
	border-radius: $plc-border-radius--m;
	box-sizing: border-box;
	color: var(--plc-role-card__main--color);
	display: grid;
	grid-template-columns: repeat(2, auto);
	grid-template-rows: 90px auto;
	justify-items: center;
	justify-self: center;
	padding: $plc-gap--m;
	position: relative;
	transition: $plc-duration--m;
	transition-property: color, background-color;
}

.plc-role-card__main:hover {
	animation-duration: $plc-duration--xl;
	animation-name: bounce;
	cursor: pointer;
	filter: contrast(70%);
}

.plc-role-card__main--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}

.plc-role-card__main--blue {
	background-image: linear-gradient(
		to bottom,
		$plc-dark-blue,
		$plc-dark-blue-70,
		var(--plc-role-card__main--bg) 85%
	);
}

.plc-role-card__main--orange {
	background-image: linear-gradient(
		to bottom,
		$plc-dark-orange,
		$plc-dark-orange-70,
		var(--plc-role-card__main--bg) 85%
	);
}

.plc-role-card__main__name {
	align-self: center;
}

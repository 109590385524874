$plc-black: #000;
$plc-blue-grey: #667592;
$plc-carbon-grey: #575757;
$plc-carbon-grey-70: #57575770;
$plc-carbon-grey-90: #57575790;
$plc-dark-blue: #2f9fb6;
$plc-dark-blue-70: #2f9fb670;
$plc-dark-green: #74af80;
$plc-dark-green-70: #74af8070;
$plc-dark-orange: #f1a14a;
$plc-dark-orange-70: #f1a14a70;
$plc-dark-purple: #955273;
$plc-dark-red: #e84e4e;
$plc-dark-yellow: #c8ab55;
$plc-dark-yellow-70: #c8ab5570;
$plc-light-blue: #cce5ff;
$plc-light-green: #d4edda;
$plc-light-grey: #d1d1d1;
$plc-light-yellow: #fbdb7d;
$plc-white: #fff;
$plc-white-20: #ffffff20;
$plc-white-70: #ffffff70;
$plc-white-90: #ffffff90;

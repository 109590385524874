.plc-footer {
	align-items: center;
	background-color: var(--plc-footer--bg);
	color: var(--plc-footer--color);
	display: grid;
	font-family: $plc-font-family--lucida;
	grid-template-columns: minmax(120px, auto) 1fr min-content;
	height: 40px;
	justify-items: center;
	padding: $plc-gap--s $plc-gap--xl;
	width: calc(100% - 40px);
}

.plc-footer__media {
	display: grid;
	grid-gap: $plc-gap--l;
	grid-template-columns: repeat(3, 1fr);
}

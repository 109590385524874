.plc-bagel-char {
	align-items: center;
	aspect-ratio: 1 / 1;
	border-radius: $plc-border-radius--round;
	color: $plc-white;
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--xxl;
	justify-items: center;
	line-height: 45px;
	white-space: nowrap;
	width: 50px;
}

.plc-bagel-char:hover {
	cursor: pointer;
}

.plc-bagel-char--none-blue {
	background: $plc-dark-blue;
}

.plc-bagel-char--none-orange {
	background: $plc-dark-orange;
}

.plc-bagel-char--hit {
	background: $plc-dark-green;
}

.plc-bagel-char--miss {
	background: $plc-dark-red;
}

.plc-bagel-char--pass {
	background: $plc-light-yellow;
}

.plc-bagel-char--blink {
	animation: blinker $plc-duration--xl linear infinite;
}

.plc-bagel-char--focused {
	font-size: $plc-font-size--xxxl;
	width: 60px;
}

.plc-bagel-char--disabled:hover {
	cursor: not-allowed;
}

// ============= Tablet styles =============
@include respond-to('medium') {
	.plc-bagel-char {
		font-size: $plc-font-size--l;
		line-height: 0;
		width: 44px;
	}

	.plc-bagel-char--focused {
		width: 54px;
	}

	// ============= Mobile styles =============
}
@include respond-to('small') {
	.plc-bagel-char {
		font-size: $plc-font-size--m;
		line-height: 0;
		width: 32px;
	}

	.plc-bagel-char--focused {
		width: 42px;
	}
}

.plc-link {
	align-items: center;
	color: var(--plc-link--color);
	cursor: pointer;
	display: grid;
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--m;
	grid-auto-flow: column;
	grid-gap: $plc-gap--m;
	text-decoration: none;
	transition: $plc-duration--m;
	transition-property: color, filter;
	width: max-content;

	&:hover {
		color: var(--plc-link--color--hover);
	}
}

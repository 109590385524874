.plc-text-input {
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-auto-flow: row;
	grid-row-gap: $plc-gap--s;
}

.plc-text-input__wrapper {
	align-items: center;
	background-color: var(--plc-text-input__wrapper--bg);
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-template-areas: 'input icon';
	grid-template-columns: 1fr 30px;
	height: 60px;
	padding: 0 $plc-gap--m;
	position: relative;
	transition: $plc-duration--m;
}

.plc-text-input__wrapper--valid {
	border-left: $plc-border-radius--m solid $plc-dark-green;
}

.plc-text-input__wrapper--invalid {
	border-left: $plc-border-radius--m solid $plc-dark-red;
}

.plc-text-input__wrapper__label {
	color: var(--plc-text-input--color);
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--l;
	left: 10px;
	position: absolute;
	top: 18px;
	transition: $plc-duration--m;
	transition-property: top, font-size, color;
}

.plc-text-input__wrapper__label--focus {
	font-size: $plc-font-size--m;
	top: 6px;
}

.plc-text-input__wrapper__label--required::after {
	color: $plc-dark-red;
	content: '*';
}

.plc-text-input__wrapper__input {
	background-color: transparent;
	border: 0;
	color: var(--plc-text-input--color);
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-area: input;
	margin: 0;
	outline: none;
	padding-top: $plc-gap--xl;
	transition: $plc-duration--m;
	width: 100%;
}

.plc-text-input__wrapper__input:not(:focus)::placeholder {
	color: transparent;
}

.plc-text-input__wrapper__icon {
	align-self: center;
	grid-area: icon;
}

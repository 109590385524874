.plc-panel {
	backdrop-filter: blur(7px);
	background-color: var(--plc-panel--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-row-gap: $plc-gap--l;
	grid-template-areas: 'header' 'main';
	grid-template-rows: 30px 1fr;
	overflow: hidden;
	padding: $plc-gap--m;
	position: relative;
	transition: $plc-duration--m;
	transition-property: background-color, color;
}

.plc-panel--no-header {
	grid-template-areas: 'main';
	grid-template-rows: 1fr;

	.plc-panel__header {
		display: none;
	}
}

.plc-panel--extra-bottom-padding {
	padding-bottom: $plc-gap--huge + $plc-gap--l;
}

.plc-panel__header {
	display: grid;
	grid-area: header;
	grid-template-areas: 'secondary title info';
	grid-template-columns: 30px 1fr 30px;
	justify-items: center;
}

.plc-panel__main {
	display: grid;
	grid-area: main;
	grid-gap: $plc-gap--m;
	overflow-y: auto;
	padding: $plc-gap--m;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		display: none;
	}

	&::-webkit-scrollbar:horizontal {
		display: none;
	}
}

.plc-panel__go-top-button {
	bottom: 10px;
	position: absolute;
	right: 20px;
}

@include respond-to('small') {
	.plc-panel {
		grid-row-gap: $plc-gap--m;
	}
}

.plc-bagels-dashboard__scores {
	align-items: center;
	display: grid;
	grid-column-gap: $plc-gap--huge;
	grid-template-columns: repeat(4, auto);
	justify-content: center;
	justify-items: center;
}

.plc-bagels-dashboard__chars {
	align-items: center;
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--m;
	overflow-x: scroll;
	scroll-behavior: smooth;
}

.plc-bagels-dashboard__chars::-webkit-scrollbar {
	display: none;
}

.plc-bagels-dashboard__definitions {
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	justify-items: center;
}

.plc-bagels-dashboard__current {
	align-items: center;
	display: grid;
	grid-template-columns: auto 1fr auto;
	justify-items: center;
}

.plc-bagels-dashboard__buttons {
	display: grid;
	grid-column-gap: $plc-gap--m;
	grid-template-columns: 1fr repeat(3, auto) 1fr;
}

.plc-bagels-dashboard__buttons--first {
	justify-self: baseline;
}

.plc-bagels-dashboard__buttons-2 {
	display: grid;
	grid-template-columns: auto 1fr auto;
	justify-items: center;
}
//====== Modal ======//

.plc-bagel-question-fix {
	display: grid;
	grid-row-gap: $plc-gap--xl;
}

.plc-bagel-question-fix__header {
	display: grid;
	grid-gap: $plc-gap--xl;
	grid-template-columns: repeat(3, auto);
	grid-template-rows: repeat(2, auto);
	justify-content: space-between;
}

.plc-bagel-question-fix__header__word-in-game {
	grid-column: 1 / span 2;
}

.plc-bagel-question-fix__main {
	display: grid;
	grid-auto-flow: column;
	justify-content: space-around;
}

@include respond-to('small') {
	.plc-bagels-dashboard__scores {
		grid-column-gap: $plc-gap--xl;
	}
}

.plc-phase-score-counter {
	backdrop-filter: blur(7px);
	background-color: var(--plc-panel--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-column-gap: $plc-gap--xxxl;
	grid-row-gap: $plc-gap--xl;
	grid-template-areas: 'bScore blank oScore' 'bAcum acumLabel oAcum';
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, auto);
	justify-items: center;
	padding: $plc-gap--xl;
}

.plc-phase-score-counter__blue-score {
	grid-area: bScore;
}

.plc-phase-score-counter__orange-score {
	grid-area: oScore;
}

.plc-phase-score-counter__blue-accumulated {
	grid-area: bAcum;
}

.plc-phase-score-counter__accumulated {
	align-self: center;
	grid-area: acumLabel;
	margin-top: $plc-gap--l;
}

.plc-phase-score-counter__orange-accumulated {
	grid-area: oAcum;
}

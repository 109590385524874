.plc-checkbox {
	align-items: center;
	color: var(--plc-checkbox--color);
	cursor: pointer;
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(2, auto);
	margin: 0;
	padding: 0;
	transition: $plc-duration--l;
	transition-property: background-color, color;
	width: max-content;

	&__input {
		display: none;
		margin: 0;
		padding: 0;

		&:checked + .plc-checkbox__checkmark::before {
			background-color: var(--plc-checkbox--bg--unchecked);
			content: '\02714';
		}

		&:checked + .plc-checkbox__slider::before {
			transform: translateX(26px);
		}

		&:checked + .plc-checkbox__slider {
			background-color: var(--plc-checkbox__slider--bg);
		}
	}

	&__checkmark {
		display: flex;

		&::before {
			background-color: var(--plc-checkbox--bg);
			border-radius: $plc-border-radius--s;
			content: '';
			display: block;
			height: 25px;
			text-align: center;
			width: 25px;
		}
	}

	&__slider {
		background-color: var(--plc-checkbox--bg);
		border-radius: $plc-border-radius--xl;
		cursor: pointer;
		height: 34px;
		position: relative;
		width: 60px;

		&::before {
			background-color: var(--plc-checkbox__slider__before--bg);
			border-radius: $plc-border-radius--round;
			bottom: 4px;
			content: '';
			height: 26px;
			left: 4px;
			position: absolute;
			transform: translateX(0);
			transition: $plc-duration--l;
			width: 26px;
		}
	}
}

.plc-toggle-button {
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	height: 60px;
	max-width: max-content !important;
	min-width: 288px;
	overflow: hidden;

	&--image-only {
		min-width: auto;
	}

	&__option {
		align-items: center;
		border: 0;
		cursor: pointer;
		display: grid;
		grid-gap: $plc-gap--m;
		justify-items: center;
		padding: 0 $plc-gap--m;

		&--image-only {
			grid-gap: 0;
		}

		&--not-selected,
		&:disabled {
			filter: brightness(0.7);
		}

		&:enabled:hover {
			filter: brightness(1.2);
		}

		&:disabled:hover {
			cursor: not-allowed;
		}

		&:first-child {
			background-color: $plc-dark-blue;
			grid-template-areas: 'name img';
			grid-template-columns: auto 40px;
		}

		&:last-child {
			background-color: $plc-dark-orange;
			grid-template-areas: 'img name';
			grid-template-columns: 40px auto;
		}

		plc-avatar {
			display: grid;
			grid-area: img;
		}
	}
}
@include respond-to('small') {
	.plc-toggle-button {
		height: 45px;
	}
	// Toggle button 2
}

.plc-toggle-button--two {
	grid-template-columns: repeat(3, 1fr);
	min-width: auto;

	.plc-toggle-button__option {
		background-color: var(--plc-button--primary--bg);
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		transition: $plc-duration--m;
		transition-property: background-color;
		width: 100px;

		plc-avatar {
			display: grid;
			grid-area: initial;
		}
	}
}

.plc-toggle-button--two--two-col {
	grid-template-columns: repeat(2, 1fr);
}

$plc-flag--size: 50px;
$plc-flag--scale: 1.1;

.plc-flag-picker {
	align-items: center;
	aspect-ratio: 1/1;
	border-radius: $plc-border-radius--m;
	display: grid;
	justify-items: center;
	overflow: visible;
	position: relative;
	transition-duration: $plc-duration--m;
	transition-property: border-radius;
	width: $plc-flag--size * $plc-flag--scale;
}

.plc-flag-picker--extended-row {
	border-radius: $plc-border-radius--m $plc-border-radius--m 0 0;
}

.plc-flag-picker--extended-column {
	border-radius: $plc-border-radius--m 0 0 $plc-border-radius--m;
}

.plc-flag-picker__flag {
	border-radius: $plc-border-radius--round;
	display: grid;
	transition-duration: $plc-duration--m;
	transition-property: border, transform;
	width: $plc-flag--size;
}

.plc-flag-picker__flag--hidden {
	display: none;
}

.plc-flag-picker__flag:hover {
	cursor: pointer;
	transform: scale($plc-flag--scale);
}

.plc-flag-picker__options {
	align-items: center;
	display: grid;
	grid-gap: $plc-gap--xl;
	justify-items: center;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	visibility: hidden;
	width: 0;
}

.plc-flag-picker__options--extended-row {
	border-radius: 0 0 $plc-border-radius--m $plc-border-radius--m;
	grid-auto-flow: row;
	height: auto;
	padding-top: $plc-gap--xl;
	top: $plc-flag--size;
	visibility: visible;
	width: $plc-flag--size * $plc-flag--scale;
}

.plc-flag-picker__options--extended-column {
	border-radius: 0 $plc-border-radius--m $plc-border-radius--m 0;
	grid-auto-flow: column;
	height: $plc-flag--size * $plc-flag--scale;
	left: $plc-flag--size;
	padding-left: $plc-gap--xl;
	visibility: visible;
	width: auto;
}

.plc-box {
	backdrop-filter: blur(7px);
	background-color: var(--plc-box--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-auto-flow: row;
	grid-row-gap: $plc-gap--l;
	overflow: hidden;
	padding: $plc-gap--m;
	position: relative;
	transition: $plc-duration--m;
	transition-property: background-color, color;
}

.plc-box--transparent {
	backdrop-filter: unset;
	background-color: transparent;
	border: 0;
}

.plc-box--no-padding {
	padding: 0;

	.plc-box__main {
		padding: 0;
	}

	.plc-box__footer {
		padding: 0;
	}
}

.plc-box__header {
	display: grid;
	grid-template-columns: 1fr 5fr 1fr;
	justify-content: space-between;
}

.plc-box__sub-header {
	display: grid;
	justify-items: center;
}

.plc-box__sub-header:empty {
	display: none;
}

.plc-box__header__center {
	color: var(--plc-label--color);
	display: grid;
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--l;
	justify-items: center;
}

.plc-box__main {
	display: grid;
	grid-gap: $plc-gap--m;
	overflow-y: auto;
	padding: $plc-gap--m;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		display: none;
	}

	&::-webkit-scrollbar:horizontal {
		display: none;
	}
}

.plc-box__main--mt {
	margin-top: $plc-gap--l;
}

.plc-box__main--row-layout {
	grid-auto-flow: row;
}

.plc-box__main--column-layout {
	grid-auto-flow: column;
}

.plc-box__footer {
	display: grid;
	grid-column-gap: $plc-gap--huge;
	grid-template-columns: repeat(2, auto);
	margin-top: $plc-gap--l;
	padding: $plc-gap--m;
}

.plc-box__header__left,
.plc-box__footer__left {
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--m;
	justify-content: start;
}

.plc-box__header__right,
.plc-box__footer__right {
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--m;
	justify-content: end;
}

@include respond-to('small') {
	.plc-box__main--mt {
		margin-top: $plc-gap--s;
	}

	.plc-box__footer {
		grid-auto-flow: row;
		grid-gap: $plc-gap--m;
		grid-template-columns: 1fr;

		.plc-button {
			width: 100%;
		}
	}

	.plc-box__footer__right,
	.plc-box__footer__left {
		grid-auto-flow: row;
		justify-content: initial;
		justify-items: center;
	}

	.plc-box__hidden-if-small {
		display: none;
	}
}

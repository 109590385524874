/// If you change bellow value, you must update FLIP_ANIMATION_DURATION constant value
/// in projects\components\src\lib\modules\game\memory-phase\flipper-cell\flipper-cell.models.ts
$plc-flipper-cell-animation-duration: $plc-duration--xxl;

.plc-flipper-cell {
	border-radius: $plc-border-radius--m;
	color: $plc-white;
	display: grid;
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--l;
	height: 60px;
	outline: none;
	transition: $plc-duration--m;
	transition-property: background-color;

	.plc-switcher-cell__presenter {
		align-content: center;
		display: grid;
		justify-content: center;
		position: relative;

		.plc-switcher-cell__presenter__index {
			left: 10px;
			position: absolute;
			top: 10px;
		}
	}

	.plc-switcher-cell__player {
		align-content: center;
		display: grid;
		justify-content: center;
	}
}

.plc-flipper-cell--blue-team {
	background-color: $plc-dark-blue;
}

.plc-flipper-cell--orange-team {
	background-color: $plc-dark-orange;
}

.plc-flipper-cell--animated-hitted,
.plc-flipper-cell--animated-none {
	animation-duration: $plc-flipper-cell-animation-duration;
	animation-name: flip-in-x;
}

.plc-flipper-cell--animated-missed {
	animation-duration: $plc-flipper-cell-animation-duration;
	animation-iteration-count: 2;
	animation-name: flip-in-x;
}

.plc-flipper-cell--hitted {
	background-color: $plc-dark-green;
}

.plc-flipper-cell--missed {
	background-color: $plc-dark-red;
}

// Mobile styles
@include respond-to('small') {
	.plc-flipper-cell {
		font-size: $plc-font-size--m;
		height: 50px;
	}
}

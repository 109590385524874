.plc-user-game-result {
	background-color: var(--plc-user-game-result--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	color: var(--plc-user-game-result--color);
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-gap: $plc-gap--l;
	grid-template-areas: 'header header header' 'scores splitter rival';
	grid-template-columns: repeat(3, auto);
	grid-template-rows: repeat(2, auto);
	padding: $plc-gap--s $plc-gap--l $plc-gap--l $plc-gap--l;
	width: min-content;
}

.plc-user-game-result--win {
	border-top: 10px solid $plc-dark-blue;
}

.plc-user-game-result--defeat {
	border-top: 10px solid $plc-dark-red;
}

.plc-user-game-result--draw {
	border-top: 10px solid $plc-dark-yellow;
}

.plc-user-game-result__header {
	display: grid;
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--l;
	grid-area: header;
	justify-items: center;
	justify-self: center;
	width: 100%;
}

.plc-user-game-result__scores {
	align-items: center;
	display: grid;
	grid-area: scores;
	grid-column-gap: $plc-gap--m;
	grid-row-gap: $plc-gap--s;
	grid-template-columns: repeat(3, auto);
	grid-template-rows: repeat(3, auto-fill);
	justify-items: center;
}

.plc-user-game-result__scores__name {
	background: var(--plc-user-game-result__name--bg);
	border-radius: $plc-border-radius--m;
	color: var(--plc-user-game-result__name--color);
	justify-self: center;
	padding: $plc-gap--s;
	text-align: center;
	width: 100%;
}

.plc-user-game-result__splitter {
	border-right: $plc-border-width--m solid
		var(--plc-user-game-result__splitter--border-color);
	height: 100%;
}

.plc-user-game-result__rival {
	display: grid;
	grid-area: rival;
	grid-gap: $plc-gap--m;
	grid-template-rows: auto 1fr;

	img {
		justify-self: center;
		width: 80px;
	}

	.plc-label {
		justify-self: center;
	}
}

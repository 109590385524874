.plc-memory-player {
	.plc-box__main {
		grid-gap: $plc-gap--xl;
		grid-template-areas: 'cam grid' 'cam clue';
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, auto);
	}
}

.plc-memory-player__cam {
	border-radius: $plc-border-radius--m;
	grid-area: cam;
}

.plc-memory-player__cam--turn {
	box-shadow: 0 0 10px 5px;
}

.plc-memory-player__cam--blue-team {
	color: $plc-dark-blue;
}

.plc-memory-player__cam--orange-team {
	color: $plc-dark-orange;
}

.plc-memory-player__clue-timer {
	align-self: end;
}
//======= Mobile Styles =========
@include respond-to('small') {
	.plc-memory-player {
		.plc-box__main {
			grid-template-areas: 'cam' 'grid' 'clue';
			grid-template-columns: 1fr;
			grid-template-rows: repeat(3, auto);
		}
	}
}

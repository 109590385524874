.plc-profile {
	.plc-tabs {
		display: none;
	}
}

.plc-profile__avatar-and-stats {
	display: grid;
	grid-gap: $plc-gap--xl;
	grid-template-columns: repeat(3, 1fr);
}

.plc-profile__last-results {
	display: grid;
	grid-auto-flow: row;
	grid-gap: $plc-gap--xl;
	grid-template-columns: repeat(3, 1fr);
	justify-items: center;
}

// ============= Mobile styles =============
@include respond-to('small') {
	.plc-profile {
		.plc-tabs {
			display: grid;
		}

		.plc-section {
			display: none;
		}
	}
}

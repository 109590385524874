$plc-black-filter: none;
$plc-carbon-grey-filter: invert(29%) sepia(0%) saturate(1940%)
	hue-rotate(193deg) brightness(99%) contrast(77%);
$plc-dark-blue-filter: invert(57%) sepia(11%) saturate(2260%) hue-rotate(143deg)
	brightness(92%) contrast(93%);
$plc-dark-green-filter: invert(69%) sepia(21%) saturate(617%) hue-rotate(80deg)
	brightness(91%) contrast(83%);
$plc-dark-orange-filter: invert(80%) sepia(45%) saturate(1999%)
	hue-rotate(325deg) brightness(100%) contrast(89%);
$plc-dark-purple-filter: invert(40%) sepia(10%) saturate(2146%)
	hue-rotate(278deg) brightness(91%) contrast(85%);
$plc-dark-red-filter: invert(32%) sepia(86%) saturate(944%) hue-rotate(326deg)
	brightness(109%) contrast(87%);
$plc-dark-yellow-filter: invert(64%) sepia(31%) saturate(666%) hue-rotate(7deg)
	brightness(103%) contrast(85%);
$plc-light-grey-filter: invert(95%) sepia(0%) saturate(1758%) hue-rotate(194deg)
	brightness(93%) contrast(83%);
$plc-white-filter: invert(100%);

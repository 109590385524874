.plc-home {
	display: grid;
	grid-template-columns: 1fr 450px;
	height: inherit;
	justify-self: center;
	left: 50%;
	margin: auto;
	max-width: 1200px !important;
	transform: translate(-50%, 0);
}

.plc-home__logo {
	display: grid;
}

.plc-home__panel {
	grid-auto-rows: max-content auto;
}

.plc-home__options {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-gap: $plc-gap--xl;
	height: min-content;
}

.plc-home__options__button {
	align-self: end;
}

.plc-home__google-badge {
	align-self: end;
	width: 100%;
}

// ============= Tablet styles =============
@media screen and (min-width: $plc-small-screen-width) and (max-width: $plc-medium-screen-width) {
	.plc-home {
		grid-template-columns: 1fr 350px;
	}

	.plc-home__panel {
		height: calc(100vh - 20px);
	}
}

// ============= Mobile styles =============
@media screen and (max-width: $plc-small-screen-width) {
	.plc-home {
		grid-row-gap: $plc-gap--xl;
		grid-template-columns: 1fr;
	}
}

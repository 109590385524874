.plc-bagels-player {
	.plc-bagel {
		justify-self: center;
	}
}

.plc-bagels-player__footer {
	align-content: center;
	align-items: end;
	display: grid;
	grid-auto-flow: column;
	justify-content: space-between;
	margin-top: -($plc-gap--xxl + $plc-gap--s);
}

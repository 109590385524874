.plc-alphabet-soup {
	display: grid;
	grid-gap: $plc-gap--s;
	grid-template-columns: repeat(5, 1fr);
	width: max-content;
}

.plc-alphabet-soup__char {
	align-items: center;
	aspect-ratio: 1/1;
	border: solid 0 transparent;
	border-radius: $plc-border-radius--m;
	box-sizing: border-box;
	color: $plc-white;
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--xxl;
	justify-items: center;
	justify-self: center;
	transition-duration: $plc-duration--m;
	transition-property: color, background-color, border, opacity;
	width: 60px;
}

.plc-alphabet-soup__char--blue-team {
	background-color: $plc-dark-blue;
}

.plc-alphabet-soup__char--orange-team {
	background-color: $plc-dark-orange;
}

.plc-alphabet-soup__char--is-not-answer {
	opacity: 0.5;
}

.plc-alphabet-soup__char--hitted {
	background-color: var(--plc-alphabet-soup__char--hitted--bg);
	border: var(--plc-alphabet-soup__char--hitted--border);
	color: var(--plc-alphabet-soup__char--hitted--color);
}

.plc-alphabet-soup__char--covered {
	color: transparent;
}

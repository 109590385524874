//===== Shared =====
%plc-loader__text-wrappers {
	background-color: var(--plc-loader--bg);
	border-radius: $plc-border-radius--m;
	box-sizing: border-box;
	color: var(--plc-loader--color);
	display: grid;
	padding: $plc-gap--m;
	place-items: center;
	width: 40%;
}
//===== Classes =====
.plc-loader {
	box-sizing: border-box;
	display: grid;
	grid-gap: $plc-gap--xxl;
	grid-template-rows: min-content 1fr min-content;
	justify-items: center;
	padding: $plc-gap--m;
}

.plc-loader__hint {
	@extend %plc-loader__text-wrappers;

	grid-gap: $plc-gap--m;
	grid-template-columns: 1fr min-content;
}

.plc-loader__hint__text {
	@extend %plc-loader__text-wrappers;

	width: 100%;
}

.plc-loader__loading-message {
	@extend %plc-loader__text-wrappers;
}

.plc-loader__logo-wrapper {
	align-items: center;
	display: grid;
	height: inherit;
	justify-items: center;
	position: relative;
}

.plc-loader__logo-wrapper__img {
	display: flex;
	height: inherit;
	position: absolute;
}

.plc-loader__logo-wrapper__img:last-child {
	animation: spin $plc-duration--xxxl * 2 linear infinite;
	animation-delay: $plc-duration--l;
}
// Mobile styles
@include respond-to('small') {
	.plc-loader {
		grid-gap: 0;
	}

	.plc-loader__hint,
	.plc-loader__loading-message {
		width: 100%;
	}

	.plc-loader__logo-wrapper {
		height: 80%;
	}
}

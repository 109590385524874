.plc-file-picker {
	plc-icon {
		justify-self: end;
	}
}

.plc-file-picker__input {
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 0.1px;
	z-index: -1;
}

.plc-file-picker__label {
	align-items: center;
	background-color: var(--plc-file-picker__label--bg);
	border-radius: $plc-border-radius--m;
	color: var(--plc-file-picker__label--color);
	cursor: pointer;
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--m;
	height: 60px;
	padding: 0 $plc-gap--xl;
	transition: $plc-duration--m;
}

.plc-file-picker__label:hover {
	transform: translateY(-5px);
}

.plc-file-picker__label__text {
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	margin: 0 !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

$plc-icon-base-size: 30px;

.plc-icon {
	transition: transform $plc-duration--m;
}

.plc-icon--90 {
	transform: rotate(90deg);
}

.plc-icon--180 {
	transform: rotate(180deg);
}

.plc-icon--270 {
	transform: rotate(270deg);
}

.plc-icon--xs {
	width: $plc-icon-base-size * 0.5;
}

.plc-icon--s {
	width: $plc-icon-base-size * 0.75;
}

.plc-icon--m {
	width: $plc-icon-base-size;
}

.plc-icon--l {
	width: $plc-icon-base-size * 1.25;
}

.plc-icon--xl {
	width: $plc-icon-base-size * 2;
}

.plc-icon--xxl {
	width: $plc-icon-base-size * 3;
}

.plc-icon--xxxl {
	width: $plc-icon-base-size * 4;
}

.plc-icon--masive {
	width: $plc-icon-base-size * 6;
}

.plc-icon__img {
	filter: var(--plc-icon--filter);
	object-fit: contain;
	transition: filter $plc-duration--m ease-in-out;
	width: inherit;
}

.plc-icon__img--hover:hover {
	cursor: pointer;
	filter: var(--plc-icon--filter-hover);
}

.plc-icon__img--reversed {
	filter: var(--plc-icon--filter-reversed);
}

.plc-icon__img--black {
	filter: $plc-black-filter;
}

.plc-icon__img--hover--black:hover {
	filter: $plc-black-filter;
}

.plc-icon__img--carbon-grey {
	filter: $plc-carbon-grey-filter;
}

.plc-icon__img--hover--carbon-grey:hover {
	filter: $plc-carbon-grey-filter;
}

.plc-icon__img--dark-blue {
	filter: $plc-dark-blue-filter;
}

.plc-icon__img--hover--dark-blue:hover {
	filter: $plc-dark-blue-filter;
}

.plc-icon__img--dark-green {
	filter: $plc-dark-green-filter;
}

.plc-icon__img--hover--dark-green:hover {
	filter: $plc-dark-green-filter;
}

.plc-icon__img--dark-orange {
	filter: $plc-dark-orange-filter;
}

.plc-icon__img--hover--dark-orange:hover {
	filter: $plc-dark-orange-filter;
}

.plc-icon__img--dark-purple {
	filter: $plc-dark-purple-filter;
}

.plc-icon__img--hover--dark-purple:hover {
	filter: $plc-dark-purple-filter;
}

.plc-icon__img--dark-red {
	filter: $plc-dark-red-filter;
}

.plc-icon__img--hover--dark-red:hover {
	filter: $plc-dark-red-filter;
}

.plc-icon__img--dark-yellow {
	filter: $plc-dark-yellow-filter;
}

.plc-icon__img--hover--dark-yellow:hover {
	filter: $plc-dark-yellow-filter;
}

.plc-icon__img--light-grey {
	filter: $plc-light-grey-filter;
}

.plc-icon__img--hover--light-grey:hover {
	filter: $plc-light-grey-filter;
}

.plc-icon__img--white {
	filter: $plc-white-filter;
}

.plc-icon__img--hover--white:hover {
	filter: $plc-white-filter;
}

.plc-disclaimer {
	align-items: center;
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	color: $plc-white;
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-gap: $plc-gap--l;
	grid-template-columns: 30px 1fr;
	padding: $plc-gap--l;
	width: auto;
}

.plc-disclaimer--info {
	background-color: $plc-dark-blue-70;
}

.plc-disclaimer--warn {
	background-color: $plc-dark-yellow-70;
}

.plc-disclaimer--success {
	background-color: $plc-dark-green-70;
}

.plc-disclaimer__text {
	margin: 0 !important;
}

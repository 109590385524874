.plc-memory-result {
	.plc-phase-score-counter {
		justify-self: center;
	}
}

.plc-memory-result__grids-row {
	display: grid;
	grid-gap: $plc-gap--xl;
	grid-template-columns: repeat(2, 1fr);
	height: 210px;
	overflow: scroll;
	padding-bottom: $plc-gap--m;
	scroll-behavior: smooth;
}

.plc-memory-result__grids-row::-webkit-scrollbar {
	display: none;
}

.plc-memory-result__grids-row::-webkit-scrollbar:horizontal {
	display: none;
}

//======= Mobile Styles =========
@include respond-to('small') {
	.plc-memory-result {
		.plc-phase-score-counter {
			justify-self: unset;
		}
	}

	.plc-memory-result__grids-row {
		grid-template-columns: repeat(2, 320px);
	}
}

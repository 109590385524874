.plc-toolbar-desktop {
	align-items: center;
	background-color: var(--plc-toolbar--bg);
	color: var(--plc-toolbar--color);
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-column-gap: $plc-gap--l;
	grid-template-columns: 60px 1fr auto;
	height: 60px;
	justify-items: right;
	padding: $plc-gap--s $plc-gap--xl;
	transition: $plc-duration--m;
	width: calc(100% - 40px);
}

.plc-desktop-menu {
	background-color: var(--plc-menu--bg);
	border-radius: $plc-border-radius--m;
	color: var(--plc-menu--color);
	cursor: pointer;
	display: block;
	position: relative;
	transition: $plc-duration--m;
}

.plc-desktop-menu__user--unfolded {
	grid-template-areas: 'wrapper' 'dropdown';
	grid-template-rows: repeat(2, auto);
}

.plc-desktop-menu__user-wrapper {
	display: grid;
	grid-column-gap: $plc-gap--l;
	grid-template-areas: 'lang greet img' 'lang name img';
	grid-template-columns: auto auto 50px;
	grid-template-rows: repeat(2, auto);
	height: 50px;
	justify-items: center;
	padding: $plc-gap--s $plc-gap--s $plc-gap--s $plc-gap--m;

	plc-flag-picker {
		display: grid;
		grid-area: lang;
	}

	plc-avatar {
		display: grid;
		grid-area: img;
		z-index: 1;
	}
}

.plc-desktop-menu__user-wrapper__name {
	font-family: $plc-font-family--lobster;
	font-size: 1.5rem;
	grid-area: name;
	text-align: center;
}

.plc-desktop-menu__dropdown {
	background-color: var(--plc-menu__options--bg);
	border-radius: 0 0 $plc-border-radius--m $plc-border-radius--m;
	display: grid;
	grid-area: dropdown;
	overflow: hidden;
	padding-top: $plc-gap--m;
	position: absolute;
	top: 55px;
	width: 100%;
}

.plc-desktop-menu__dropdown__option {
	align-items: center;
	cursor: pointer;
	display: grid;
	grid-column-gap: $plc-gap--m;
	grid-template-columns: repeat(2, auto);
	justify-content: start;
	padding: $plc-gap--m;
	padding-left: $plc-gap--xl;
}

.plc-desktop-menu__dropdown__option:first-child {
	border-top: $plc-border-width--s solid $plc-white;
}

.plc-desktop-menu__dropdown__option:last-child {
	border-radius: 0 0 $plc-border-radius--m $plc-border-radius--m;
}

.plc-desktop-menu__dropdown__option:hover {
	background: var(--plc-menu__options__option--bg);
	color: var(--plc-menu__options__option--color);

	.plc-desktop-menu__dropdown__option__icon {
		filter: var(--plc-menu__options__option__icon--filter--hover);
	}
}

.plc-desktop-menu__dropdown__option__icon {
	filter: var(--plc-menu__options__option__icon--filter);
	height: 25px;
	transition: $plc-duration--m;
}

.plc-desktop-menu__dropdown__volume {
	background-color: $plc-carbon-grey-70;
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: min-content auto;
	padding: $plc-gap--m $plc-gap--xl;
}

.plc-desktop-menu__no-user-wrapper {
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--m;
	padding: $plc-gap--s $plc-gap--m;
}

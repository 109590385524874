.plc-stepper {
	align-content: center;
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	justify-self: center;
	width: min-content;
}

.plc-stepper__wrapper {
	align-content: center;
	display: grid;
	grid-auto-flow: column;
	justify-content: center;
	justify-self: center;
	width: min-content;
}

.plc-stepper__step {
	align-items: center;
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--m;
}

.plc-stepper__step__name {
	color: var(--plc-stepper__step__name--color);
	margin: 0 !important;
	text-align: center;
}

.plc-stepper__step__dot {
	background-color: var(--plc-stepper__step--bg);
	border-radius: $plc-border-radius--round;
	height: 15px;
	transition: $plc-duration--m;
	transition-delay: $plc-duration--xl;
	transition-property: background-color, outline, outline-offset;
	width: 15px;
}

.plc-stepper__step__dot--curr {
	background-color: var(--plc-stepper__step--curr--bg);
	outline: 3px solid var(--plc-stepper__step--curr--bg);
	outline-offset: $plc-gap--s;
}

.plc-stepper__step__line {
	background-color: var(--plc-stepper__step--bg);
	height: 2px;
	position: relative;
	width: 70px;
}

.plc-stepper__step__line::after {
	background-color: var(--plc-stepper__step--curr--bg);
	content: '';
	height: 2px;
	position: absolute;
	transition: $plc-duration--xl;
	transition-property: width;
	width: 0%;
}

.plc-stepper__step__line--curr::after {
	width: 100%;
}

.plc-stepper__name-mobile {
	display: none;
}

@include respond-to('small') {
	.plc-stepper {
		grid-row-gap: $plc-gap--m;
	}

	.plc-stepper__step {
		grid-gap: 0;
		grid-row: 1;
	}

	.plc-stepper__step__line {
		width: 30px;
	}

	.plc-stepper__step__name {
		display: none;
	}

	.plc-stepper__name-mobile {
		color: var(--plc-stepper__step__name--color);
		display: grid;
		justify-self: center;
		width: max-content;
	}
}

.plc-bagels-result__bagels-row {
	display: grid;
	grid-gap: $plc-gap--xl;
	grid-template-columns: repeat(2, 1fr);
	overflow: scroll;
	scroll-behavior: smooth;
}

.plc-bagels-result__bagels-row::-webkit-scrollbar {
	display: none;
}

.plc-bagels-result__scores {
	align-items: center;
	background: var(--plc-panel--bg);
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-auto-flow: column;
	justify-content: space-between;
	justify-self: center;
	min-width: 320px !important;
	padding: $plc-gap--m;
}

.plc-bagels-result__scores > * {
	align-items: center;
	display: flex;
	flex-direction: column;
	grid-row-gap: $plc-gap--m;
	justify-content: center;
	padding: 0 $plc-gap--xl;
}

.plc-bagels-result__winner {
	display: grid;
	grid-gap: $plc-gap--m;
}

.plc-bagels-result__winner__name {
	justify-self: center;
}

.plc-bagels-result__winner {
	.plc-panel__main {
		grid-auto-flow: column;
		justify-content: space-between;
	}
}

.plc-soups-dashboard__scores {
	align-items: center;
	display: grid;
	grid-column-gap: $plc-gap--xl;
	grid-template-columns: repeat(4, auto);
	justify-content: center;
	justify-items: center;
}

.plc-soups-dashboard__round-box--web {
	display: grid;

	.plc-soups-dashboard__answers-box {
		display: grid;
		grid-gap: $plc-gap--m;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);

		/* stylelint-disable-next-line max-nesting-depth */
		.plc-label:first-child {
			grid-column: 1 / -1;
			justify-self: center;
		}
	}

	.plc-soups-dashboard__answers-box__answer {
		display: grid;
		grid-auto-flow: column;
		grid-gap: $plc-gap--m;
		justify-content: start;
	}
}

.plc-soups-dashboard__round-box--mobile {
	display: none;
}

.plc-soups-dashboard__turn-box {
	display: grid;
	grid-gap: $plc-gap--xl;
	grid-template-columns: 1fr 1fr;
	justify-items: start;

	/* stylelint-disable-next-line no-descending-specificity */
	.plc-label {
		justify-self: end;
	}

	.plc-avatar__img {
		width: 40%;
	}
}

.plc-alphabet-soup--dashboard {
	justify-self: center;

	.plc-alphabet-soup__char {
		font-size: $plc-font-size--xxl;
		width: 50px;
	}
}

.plc-soups-dashboard__clue-label {
	justify-self: center;

	.plc-label__text::first-letter {
		text-transform: uppercase;
	}
}

.plc-soups-dashboard__buttons {
	display: grid;
	grid-column-gap: $plc-gap--m;
	grid-template-columns: 1fr repeat(3, auto) 1fr;

	plc-button:first-child {
		justify-self: baseline;
	}

	plc-button:last-child {
		justify-self: end;
	}
}

.plc-soups-dashboard__buttons-2 {
	display: grid;
	grid-gap: $plc-gap--xl;
	grid-template-columns: repeat(3, 1fr);
	justify-items: start;
}

//================= Fix panel =========================

.plc-soup-fix__answers-box {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);

	/* stylelint-disable-next-line no-descending-specificity */
	.plc-label:first-child {
		grid-column: 1 / -1;
	}
}

.plc-soup-fix__form-row-2 {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(2, 1fr);

	.plc-number-input {
		justify-self: center;
	}
}

.plc-soup-fix__turn-box {
	align-items: center;
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(3, 1fr);
	width: min-content;

	.plc-avatar__img {
		width: 50px;
	}
}

@include respond-to('small') {
	.plc-soups-dashboard__round-box--web {
		display: none;
	}

	.plc-soups-dashboard__round-box--mobile {
		display: grid;

		.plc-soups-dashboard__answers-box {
			justify-content: start;
		}

		.plc-soups-dashboard__turn-label {
			align-self: center;
		}
	}

	.plc-soups-dashboard__buttons__hit-btn {
		min-width: 0;
		width: 50px;
	}
}

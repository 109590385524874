.plc-score-counter {
	align-content: center;
	aspect-ratio: 1 / 1;
	border-radius: $plc-border-radius--round;
	color: var(--plc-score-counter--color);
	display: grid;
	font: $plc-font-size--l $plc-font-family--lobster;
	justify-content: center;
	transition-duration: $plc-duration--m;
	transition-property: text-shadow, color;
	transition-timing-function: ease-in-out;
	width: min-content;
}

.plc-score-counter--medium {
	font: $plc-font-size--xxl $plc-font-family--lobster;
}

.plc-score-counter--big {
	font: $plc-font-size--xxxl $plc-font-family--lobster;
}

.plc-score-counter--blue {
	background: $plc-dark-blue-70;
	padding: $plc-gap--m;
}

.plc-score-counter--orange {
	background: $plc-dark-orange-70;
	padding: $plc-gap--m;
}

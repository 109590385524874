$plc-border-width--s: 1px;
$plc-border-width--m: 2px;
$plc-border-width--l: 5px;
$plc-border-width--xl: 8px;

$plc-border-radius--s: 5px;
$plc-border-radius--m: 10px;
$plc-border-radius--l: 15px;
$plc-border-radius--xl: 34px;
$plc-border-radius--round: 50%;

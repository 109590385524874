.plc-wizard {
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;

	&__hole {
		background-color: transparent;
		border-radius: $plc-border-radius--m;
		box-shadow: 0 0 0 200vmax var(--plc-wizard__hole--bs);
		position: absolute;
		transition: $plc-duration--m;
		transition-property: top, left;
	}
}

.plc-tooltip {
	animation-duration: $plc-duration--m;
	animation-fill-mode: both;
	backdrop-filter: blur(7px);
	background-color: var(--plc-panel--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	color: var(--plc-tooltip--color);
	display: grid;
	filter: drop-shadow(0 0 6px $plc-blue-grey);
	grid-gap: $plc-gap--m;
	overflow: visible;
	padding: $plc-gap--m;
	position: absolute;
	width: 460px;

	&--current {
		animation-delay: $plc-duration--m;
		animation-name: bounce-in;
	}

	&--previous {
		animation-name: bounce-out;
	}

	&--hidden {
		animation: none;
		visibility: hidden;
	}

	&__header {
		display: grid;
		font-family: $plc-font-family--lobster;
		font-size: $plc-font-size--l;
		justify-content: center;
		width: 100%;
	}

	&__content {
		display: grid;
		grid-auto-flow: row;
		grid-row-gap: $plc-gap--xl;
		text-align: justify;
		z-index: 1;

		&__text {
			a {
				color: var(--plc-tooltip__content__text__anchor--color);
			}

			img {
				filter: var(--plc-tooltip__content__text__img--filter);
				height: 30px;
				vertical-align: middle;
			}
		}

		&__buttons {
			align-items: center;
			display: grid;
			grid-gap: $plc-gap--m;
			grid-template-areas: 'left count right none skip';
			grid-template-columns: repeat(3, auto) 1fr auto;
		}
	}

	&__pointer {
		background-color: var(--plc-panel--bg);
		border: none;
		border-radius: 0 0 0 $plc-border-radius--m;
		clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
		display: block;
		height: 35px;
		position: absolute;
		width: 35px;

		&--none {
			visibility: hidden;
		}

		&--left {
			transform: rotate(-315deg);
		}

		&--top {
			transform: rotate(-225deg);
		}

		&--right {
			transform: rotate(-135deg);
		}

		&--bottom {
			transform: rotate(-45deg);
		}
	}
}

// ============= Mobile styles =============
@include respond-to('small') {
	.plc-tooltip {
		width: 270px;

		&__content__buttons {
			grid-template-areas: 'left count right skip';
			grid-template-columns: repeat(4, auto);

			&__check {
				display: none;
			}

			&__check-slide {
				display: grid;
			}
		}
	}
}

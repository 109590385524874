.plc-raising-panel {
	backdrop-filter: blur(7px);
	background-color: var(--plc-panel--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m $plc-border-radius--m 0 0;
	bottom: 0;
	display: grid;
	grid-row-gap: $plc-gap--l;
	grid-template-areas: 'header' 'main';
	grid-template-rows: 30px 1fr;
	max-height: 0 !important;
	overflow: hidden;
	padding: 0 $plc-gap--m;
	position: absolute;
	transition: $plc-duration--m ease-out;
	transition-property: max-height, background-color, color, padding;
	width: 100%;

	&--toggled {
		box-shadow: 0 0 0 100vmax var(--plc-raising-panel--bs);
		max-height: calc(100vh - 70px) !important;
		padding: $plc-gap--m;
		transition: $plc-duration--m ease-in;
	}

	&__header {
		display: grid;
		grid-area: header;
		grid-template-columns: 30px 1fr 30px;
		justify-items: center;
	}

	&__main {
		display: grid;
		grid-area: main;
		grid-row-gap: $plc-gap--m;
		overflow: scroll;
		padding: $plc-gap--m;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

/* stylelint-disable keyframe-block-no-duplicate-selectors */
@keyframes blinker {
	50% {
		opacity: 0.4;
	}
}

@keyframes bounce {
	0%,
	20%,
	53%,
	80%,
	100% {
		transform: translate3d(0, 0, 0);
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	40%,
	43% {
		transform: translate3d(0, -30px, 0);
		transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}

	70% {
		transform: translate3d(0, -15px, 0);
		transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}

	90% {
		transform: translate3d(0, -4px, 0);
	}
}

@keyframes bounce-in {
	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	20% {
		transform: scale3d(1.1, 1.1, 1.1);
	}

	40% {
		transform: scale3d(0.9, 0.9, 0.9);
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% {
		transform: scale3d(0.97, 0.97, 0.97);
	}

	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}

@keyframes bounce-out {
	20% {
		transform: scale3d(0.9, 0.9, 0.9);
	}

	50%,
	55% {
		opacity: 1;
		transform: scale3d(1.1, 1.1, 1.1);
	}

	100% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
}

$plc-flip-perspective: 1000px;

@keyframes flip {
	0% {
		animation-timing-function: ease-out;
		transform: perspective($plc-flip-perspective) rotate3d(0, 1, 0, -360deg);
	}

	40% {
		animation-timing-function: ease-out;
		transform: perspective($plc-flip-perspective) translate3d(0, 0, 150px)
			rotate3d(0, 1, 0, -190deg);
	}

	50% {
		animation-timing-function: ease-in;
		transform: perspective($plc-flip-perspective) translate3d(0, 0, 150px)
			rotate3d(0, 1, 0, -170deg);
	}

	80% {
		animation-timing-function: ease-in;
		transform: perspective($plc-flip-perspective) scale3d(0.95, 0.95, 0.95);
	}

	100% {
		animation-timing-function: ease-in;
		transform: perspective($plc-flip-perspective);
	}
}

@keyframes flip-in-x {
	0% {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		transition-timing-function: ease-in;
	}

	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		transition-timing-function: ease-in;
	}

	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	}

	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}

	100% {
		transform: perspective(400px);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes slide-in-down {
	0% {
		transform: translateY(-100%);
		visibility: visible;
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes slide-in-left {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}

@keyframes slide-in-up {
	0% {
		transform: translateY(100%);
		visibility: visible;
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes stretch-horizontal {
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}

@keyframes zoom-in {
	0% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

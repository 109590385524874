.plc-background-screen {
	bottom: 0;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;

	&--plain {
		background: var(--plc-background-screen--plain--bg);
	}

	&--gradient {
		background: linear-gradient(
			to bottom,
			$plc-dark-purple 12%,
			$plc-blue-grey 64%,
			$plc-dark-blue 100%
		);
	}

	&--transparent {
		background: transparent;
	}
}

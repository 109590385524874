@use 'sass:math';

$plc-image-cropper-size: 340px;

.plc-image-cropper {
	display: grid;
	width: $plc-image-cropper-size;
	grid-gap: $plc-gap--m;
}

.plc-image-cropper__wrapper {
	width: $plc-image-cropper-size;
	height: $plc-image-cropper-size;
	position: relative;
	border-radius: $plc-border-radius--m;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.plc-image-cropper__wrapper__img {
	max-width: 100% !important;
	height: auto;
}

.plc-image-cropper__wrapper__box {
	position: absolute;
	border: $plc-border-width--m solid $plc-white;
	border-radius: $plc-border-radius--round;
	box-shadow: 0 0 0 100vmax $plc-carbon-grey-70;
	overflow: hidden;
}

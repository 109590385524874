/// Properies to make body as browser size, no padding, no scroll
%browser-size {
	height: 100%;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: fixed;
	width: 100%;
}

.plc-hidden-if-childs-are-empty {
	display: none !important;
}

.plc-check-panel-group {
	display: flex;
	flex-wrap: wrap;
	grid-gap: $plc-gap--xl;
	justify-content: space-between;
}

.plc-check-panel-group--vertical {
	flex-direction: column;
}

.plc-check-panel-group--horizontal {
	flex-direction: row;
}

.plc-check-panel-group--disabled {
	cursor: not-allowed;
	opacity: 0.7;
	pointer-events: none;
}

.plc-check-panel {
	background: var(--plc-check-panel--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	display: grid;
	flex: 1 0 23%;
	padding: $plc-gap--m;
	transition:
		border,
		background $plc-duration--m ease-in-out;
}

.plc-check-panel:hover {
	background: $plc-light-grey;
	cursor: pointer;
}

.plc-check-panel--checked,
.plc-check-panel--checked:hover {
	border: $plc-border-width--l solid var(--plc-check-panel--color--checked);
}

.plc-section {
	background-color: var(--plc-panel--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-auto-flow: row;
	padding: $plc-gap--m;
	transition: grid-row-gap $plc-duration--m ease-out;
}

.plc-section--toggled {
	grid-row-gap: $plc-gap--xl;

	.plc-section__main {
		max-height: 100vh !important;
		transition: max-height $plc-duration--m ease-in;
	}
}

.plc-section__header {
	align-items: center;
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: max-content 1fr;
	transition:
		color,
		filter $plc-duration--m;
}

.plc-section__main {
	display: grid;
	grid-gap: $plc-gap--m;
	max-height: 0 !important;
	overflow: hidden;
	transition: max-height $plc-duration--m ease-out;
}

.plc-section__main--column-layout {
	grid-auto-flow: column;
}

.plc-section__main--row-layout {
	grid-auto-flow: row;
}

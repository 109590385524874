.plc-memory-viewer {
	.plc-tab {
		grid-gap: $plc-gap--xl;
		grid-template-areas: 'avatar grid' 'avatar clue';
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, auto);
		justify-items: initial;

		plc-avatar {
			display: grid;
			grid-area: avatar;
		}

		.plc-flipper-grid {
			grid-area: grid;
			width: 400px;
		}

		.plc-clue-timer {
			align-self: end;
			grid-area: clue;
		}
	}
}
//======= Mobile Styles =========
@include respond-to('small') {
	.plc-memory-viewer {
		.plc-tab {
			grid-template-areas: 'avatar' 'grid' 'clue';
			grid-template-columns: 1fr;
			grid-template-rows: repeat(3, auto);
			justify-items: stretch;

			.plc-flipper-grid {
				width: auto;
			}
		}
	}
}

/* stylelint-disable max-nesting-depth */
.plc-song-dashboard__players-row {
	display: grid;
	grid-gap: $plc-gap--xl;
	grid-template-columns: repeat(2, auto);
	justify-content: center;
}

.plc-song-dashboard__players-row__avatar {
	border-radius: $plc-border-radius--round;
	box-shadow: 0 0 10px 5px;
	display: grid;
	height: 100px;
	transition: $plc-duration--m;
	transition-property: transform, box-shadow;
	width: 100px;
}

.plc-song-dashboard__players-row__avatar--blue-team {
	color: $plc-dark-blue;
}

.plc-song-dashboard__players-row__avatar--orange-team {
	color: $plc-dark-orange;
}

.plc-song-dashboard__players-row__avatar--turn-owner {
	transform: scale(1.05);
}

.plc-song-dashboard__players-row__avatar--turn-missed {
	box-shadow: 0 0 0 0;
	transform: scale(0.9);
}

.plc-song-dashboard__lyrics {
	.plc-raising-panel__main,
	.plc-dialog__main {
		justify-items: center;
	}
}

.plc-song-dashboard__clue-row {
	display: grid;
	justify-content: start;
}

.plc-song-dashboard__winner-panel {
	.plc-box__main {
		grid-template: repeat(2, auto), repeat(3, auto);
		grid-template-areas: 'name score' 'song song' 'clue clue';
		justify-items: baseline;

		.plc-song-dashboard__winner-panel__score {
			justify-self: end;
		}

		.plc-song-dashboard__winner-panel__song {
			grid-area: song;
		}

		.plc-song-dashboard__winner-panel__clue {
			grid-area: clue;
		}
	}
}

.plc-song-dashboard__buttons-row {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(4, 1fr);

	plc-button:nth-child(even) {
		justify-self: end;
	}
}

.plc-song-dashboard__round-toggle {
	justify-self: center;
	margin-top: $plc-gap--xl + $plc-gap--s;
}

.plc-radio-buttons {
	color: var(--plc-radio-buttons__color);
	display: grid;
	grid-gap: $plc-gap--l;
	grid-template-rows: repeat(2, auto);
	height: 60px;
	margin: 0;
	padding: 0;
	width: max-content;

	&__title {
		display: grid;
		font-family: $plc-font-family--lobster;
		font-size: $plc-font-size--m;
		grid-column: 1 / -1;
	}

	&__options {
		display: grid;
		grid-auto-flow: column;
		grid-gap: $plc-gap--m;

		&__label {
			align-self: center;
			cursor: pointer;
			display: grid;
			font-family: $plc-font-family--lucida;
			font-size: $plc-font-size--m;
			padding-left: $plc-gap--xxl + $plc-gap--s;
			position: relative;
			user-select: none;

			&:hover input ~ .checkmark {
				background-color: $plc-light-grey;
			}

			input {
				cursor: pointer;
				height: 0;
				opacity: 0;
				position: absolute;
				width: 0;

				&:checked ~ .checkmark {
					background-color: var(--plc-radio-buttons__checkmark--bg);
				}

				&:checked ~ .checkmark::after {
					display: block;
				}
			}

			.checkmark {
				background-color: $plc-white;
				border-radius: $plc-border-radius--round;
				height: 25px;
				left: 0;
				position: absolute;
				top: -2px;
				transition: $plc-duration--m;
				width: 25px;

				&::after {
					background: $plc-white;
					border-radius: $plc-border-radius--round;
					content: '';
					display: none;
					height: 8px;
					left: 8.5px;
					position: absolute;
					top: 8.5px;
					width: 8px;
				}
			}
		}
	}
}

.plc-number-input {
	display: grid;
	grid-auto-flow: row;
	grid-gap: $plc-gap--m;

	input {
		appearance: none;
		background-color: transparent;
		border: 0;
		color: var(--plc-text-input--color);
		font-family: $plc-font-family--lucida;
		font-size: $plc-font-size--m;
		outline: none;
		padding: 0;
		text-align: center;
		transition:
			color,
			background-color $plc-duration--m;
		width: 50px;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		appearance: none;
		margin: 0;
	}

	/* stylelint-disable-next-line selector-no-qualifying-type */
	input[type='number'] {
		appearance: textfield;
	}

	button {
		appearance: none;
		background-color: transparent;
		border: 0;
		color: var(--plc-text-input--color);
		cursor: pointer;
		font-family: $plc-font-family--lobster;
		font-size: $plc-font-size--xxl;
		outline: none;
		padding: 0 $plc-gap--l;
		transition:
			color,
			background-color $plc-duration--m;
	}

	button:disabled {
		color: $plc-light-grey;
		cursor: not-allowed;
	}
}

.plc-number-input__label {
	color: var(--plc-text-input--color);
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--m;
}

.plc-number-input__wrapper {
	background-color: var(--plc-text-input__wrapper--bg);
	border-radius: $plc-border-radius--xl;
	display: grid;
	grid-template-columns: repeat(3, auto);
	transition:
		color,
		background-color $plc-duration--m;
	width: max-content;
}

.plc-number-input__label--required::after {
	color: $plc-dark-red;
	content: '*';
}

.plc-new-game__player-option,
.plc-new-game__type-option {
	align-items: center;
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--l;
}

.plc-new-game__player-option__icons {
	align-items: center;
	border-radius: $plc-border-radius--round;
	display: grid;
	justify-items: center;
	margin: $plc-gap--m;
	position: relative;

	plc-icon {
		position: absolute;
	}
}

.plc-new-game__player-option__icons--2p {
	height: 30px;
	width: 30px;
}

.plc-new-game__player-option__icons--3p {
	height: 40px;
	width: 40px;
}

.plc-new-game__player-option__icons--5p {
	height: 50px;
	width: 50px;
}

.plc-new-game__player-option__icons--7p {
	height: 70px;
	width: 70px;
}

.plc-memory-preview {
	.plc-memory-preview__instruction {
		align-self: center;
		justify-self: baseline;
	}

	.plc-flipper-grid {
		animation: flip $plc-duration--xxl * 2 infinite;
		animation-delay: $plc-duration--m;
		animation-fill-mode: both;
		backface-visibility: visible;
		margin: $plc-gap--l $plc-gap--xxxl;
		width: 472px;
	}
}

.plc-memory-overview__players-section {
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	.plc-memory-overview__players-section__avatar {
		aspect-ratio: 1/1;
		border-radius: $plc-border-radius--round;
		width: 100px;
	}

	.plc-memory-overview__players-section__presenter {
		align-self: center;
		display: grid;
		justify-items: center;
	}

	.plc-memory-overview__players-section__team {
		display: grid;
		grid-auto-flow: row;
		grid-column-gap: $plc-gap--m;
		grid-template-columns: repeat(2, 1fr);
		justify-items: center;
	}

	.plc-memory-overview__players-section__team > * {
		display: grid;
		justify-items: center;
	}

	.plc-memory-overview__players-section__team > :first-child {
		grid-column: 1/-1;
	}

	//======= Mobile Styles =========
}
@include respond-to('small') {
	.plc-memory-preview {
		.plc-flipper-grid {
			margin: $plc-gap--m $plc-gap--xl;
			width: initial;
		}
	}

	.plc-memory-overview__players-section__team {
		grid-template-columns: 1fr !important;
	}
}

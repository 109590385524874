.plc-alphabet-soup__viewer__tab {
	grid-auto-flow: column;
	justify-items: initial;
}

.plc-alphabet-soup__viewer__tab__avatar {
	img {
		height: 400px;
	}
}

.plc-alphabet-soup--viewer {
	justify-self: center;

	.plc-alphabet-soup__char {
		font-size: $plc-font-size--xl;
		width: 50px;
	}
}

@include respond-to('small') {
	.plc-alphabet-soup__viewer__tab {
		grid-auto-flow: initial;
	}

	.plc-alphabet-soup__viewer__tab__avatar {
		img {
			height: 200px;
		}
	}
}

$plc-font-family--lucida: 'Lucida Sans Unicode', sans-serif;
$plc-font-family--lobster: 'Lobster', cursive;

$plc-font-size--xs: 0.5rem;
$plc-font-size--s: 0.75rem;
$plc-font-size--m: 1rem;
$plc-font-size--l: 1.5rem;
$plc-font-size--xl: 1.75rem;
$plc-font-size--xxl: 2rem;
$plc-font-size--xxxl: 3rem;

.plc-webcam {
	align-items: center;
	border-radius: inherit;
	display: flex;
	height: inherit;
	justify-content: center;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: inherit;

	&__error-wrapper {
		display: grid;
		grid-gap: $plc-gap--xl;
		justify-items: center;
		justify-self: center;

		&__message {
			font-family: $plc-font-family--lucida;
			font-size: $plc-font-size--m;
			width: 60%;
		}
	}
}

.plc-pin-code {
	background-color: var(--plc-pin-code--bg);
	color: var(--plc-pin-code--color);
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-auto-flow: row;
	grid-gap: $plc-gap--m;
	width: 100%;
}

.plc-pin-code__wrapper {
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--s;
	transition: $plc-duration--m;
	width: min-content;
}

.plc-pin-code__wrapper__input-char {
	background-color: var(--plc-text-input__wrapper--bg);
	border: 0;
	border-radius: $plc-border-radius--m;
	box-sizing: initial !important;
	color: var(--plc-text-input--color);
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	height: 25px;
	margin: 0;
	outline: none;
	padding: $plc-gap--s;
	text-align: center;
	transition: $plc-duration--m;
	width: 20px;
}

.plc-pin-code__wrapper__input-char[type='password'] {
	font-size: $plc-font-size--xxl;
}

.plc-pin-code__wrapper__input-char:disabled {
	cursor: not-allowed;
	opacity: 0.7;
}

.plc-pin-code__wrapper__icon {
	align-self: center;
	display: grid;
}

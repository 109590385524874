.plc-html .plc-body {
	@extend %browser-size;
}

.plc-root {
	background: linear-gradient(
		to bottom,
		$plc-dark-purple 12%,
		$plc-blue-grey 64%,
		$plc-dark-blue 100%
	);
	container-type: inline-size;
	display: grid;
	grid-template-areas: 'toolbar' 'content' 'footer';
	grid-template-rows: 70px 1fr 50px;
	height: inherit;
}

.plc-router-container {
	box-sizing: border-box;
	grid-area: content;
	padding: $plc-gap--xl;

	& > * {
		align-content: center;
		display: grid;
		justify-content: center;
		position: fixed;
		width: calc(100vw - 40px);

		& * {
			box-sizing: border-box;
			max-height: calc(100vh - 160px);
			max-width: 1000px;
		}
	}
}

.plc-toast {
	margin-top: $plc-gap--huge + $plc-gap--xxl;
}

.plc-raising-panel {
	display: none;
}

.plc-toolbar-desktop {
	display: grid;
	grid-area: toolbar;
}

.plc-footer {
	display: grid;
}

.plc-toolbar-mobile {
	display: none;
}
// ============= Medium Screen styles =============
@media screen and (min-width: $plc-small-screen-width) and (max-width: $plc-medium-screen-width) {
	.plc-root {
		grid-template-areas: 'toolbar content';
		grid-template-columns: min-content 1fr;
		grid-template-rows: 1fr;

		.plc-router-container {
			box-sizing: border-box;
			justify-content: initial;
			padding: $plc-gap--m;

			& > * {
				align-content: baseline;
				height: calc(100% - 20px);
				justify-content: center;
				width: calc(100% - 70px);

				& * {
					max-height: calc(100vh - 20px);
				}
			}
		}
	}

	.plc-root--full-height {
		grid-template-columns: 1fr;

		.plc-router-container {
			& > * {
				height: calc(100% - 20px);

				& * {
					max-height: calc(100vh - 20px);
				}
			}
		}
	}

	.plc-toolbar-mobile {
		display: grid;
	}

	.plc-toolbar-desktop {
		display: none;
	}

	.plc-footer {
		display: none;
	}
}

// ============= Small Screen styles =============
@media screen and (max-width: $plc-small-screen-width) {
	.plc-root {
		grid-template-areas: 'content' 'toolbar';
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 50px;

		.plc-router-container {
			box-sizing: border-box;
			justify-content: initial;
			padding: $plc-gap--m;

			& > * {
				align-content: baseline;
				height: calc(100% - 70px);
				justify-content: initial;
				width: calc(100vw - 20px);

				& * {
					max-height: calc(100vh - 70px);
				}
			}
		}
	}

	.plc-root--full-height {
		grid-template-rows: 1fr;

		.plc-router-container {
			& > * {
				height: calc(100% - 20px);

				& * {
					max-height: calc(100vh - 20px);
				}
			}
		}
	}

	.plc-toast {
		margin-top: 0;
	}

	.plc-toolbar-mobile {
		display: grid;
	}

	.plc-toolbar-desktop {
		display: none;
	}

	.plc-footer {
		display: none;
	}

	.plc-raising-panel {
		bottom: -10px;
		display: grid;
	}

	.plc-dialog-wrapper {
		display: none !important;
	}
}

.plc-bagels-preview {
	.plc-bagels-preview__instruction {
		align-self: center;
		justify-self: baseline;
	}

	.plc-bagel {
		animation: flip $plc-duration--xxl * 2 infinite;
		animation-delay: $plc-duration--m;
		animation-fill-mode: both;
		backface-visibility: visible;
	}
}

[data-theme='light'] {
	//Alphabet soup
	--plc-alphabet-soup__char--hitted--border: solid 4px #{$plc-carbon-grey};
	--plc-alphabet-soup__char--hitted--bg: #{$plc-carbon-grey};
	--plc-alphabet-soup__char--hitted--color: #{$plc-white};

	//Background-screen
	--plc-background-screen--plain--bg: #{$plc-light-grey};

	//Bagel dial
	--plc-bagel-dial--border-color: #{$plc-white-70};

	// Bagels
	--plc-bagel__no-avatar--filter: #{$plc-carbon-grey-filter};

	//Box
	--plc-box--bg: #{$plc-white-70};

	//Button
	--plc-button--primary--color: #{$plc-white};
	--plc-button--primary--bg: #{$plc-dark-yellow};
	--plc-button--primary__icon--filter: #{$plc-white-filter};
	--plc-button--secondary--color: #{$plc-white};
	--plc-button--secondary--bg: #{$plc-black};
	--plc-button--secondary__icon--filter: #{$plc-white-filter};
	--plc-button--outline--color: #{$plc-black};
	--plc-button--outline--border: 2px solid #{$plc-black};
	--plc-button--outline__icon--filter: #{$plc-black-filter};

	//Checkbox
	--plc-checkbox--bg: #{$plc-white};
	--plc-checkbox--bg--unchecked: #{$plc-dark-yellow};
	--plc-checkbox--color: #{$plc-black};
	--plc-checkbox__slider--bg: #{$plc-dark-yellow};
	--plc-checkbox__slider__before--bg: #{$plc-black};

	//Check Panel
	--plc-check-panel--bg: #{$plc-white};
	--plc-check-panel--color--checked: #{$plc-dark-yellow-70};

	// Clue timer
	--plc-clue-timer__timer--border-color: #{$plc-white-70};

	// Dialog
	--plc-dialog--bs: #{$plc-carbon-grey-70};
	--plc-dialog--bg: #{$plc-white-70};

	// Donut chart
	--plc-donut-chart--color: #{$plc-white};
	--plc-donut-chart--bg: #{$plc-white};
	--plc-donut-chart__legend--bg: #{$plc-carbon-grey};

	//File Picker
	--plc-file-picker__label--color: #{$plc-white};
	--plc-file-picker__label--bg: #{$plc-dark-yellow};

	//Footer
	--plc-footer--bg: #{$plc-white-90};
	--plc-footer--color: #{$plc-black};

	// Games row
	--plc-games-row--bg: #{$plc-white-70};
	--plc-games-row__icon--filter: #{$plc-black-filter};

	// Icon
	--plc-icon--filter: #{$plc-black-filter};
	--plc-icon--filter-hover: #{$plc-dark-yellow-filter};
	--plc-icon--filter-reversed: #{$plc-white-filter};

	// Icon badge
	--plc-icon-badge--filter: #{$plc-black-filter};

	//Label
	--plc-label--color: #{$plc-black};
	--plc-label--color-reversed: #{$plc-white};

	//Link
	--plc-link--color: #{$plc-black};
	--plc-link--color--hover: #{$plc-dark-yellow};
	--plc-link__icon-filter: #{$plc-black-filter};
	--plc-link__icon-filter--hover: #{$plc-dark-yellow-filter};

	//Loader
	--plc-loader--bg: #{$plc-white-70};
	--plc-loader--color: #{$plc-black};

	//Panel
	--plc-panel--bg: #{$plc-white-70};
	--plc-panel-scroll-track--bg: #{$plc-dark-yellow};
	--plc-panel-scroll-thumb--bg: #{$plc-black};

	// Progress bar
	--plc-progress-bar--bg: #{$plc-carbon-grey-70};

	//Radio buttons
	--plc-radio-buttons__color: #{$plc-black};
	--plc-radio-buttons__checkmark--bg: #{$plc-dark-yellow};

	//Raising panel
	--plc-raising-panel--bs: #{$plc-carbon-grey-70};

	// Range input
	--plc-range-input--bg-1: #{$plc-dark-yellow};
	--plc-range-input--bg-2: #{$plc-white};
	--plc-range-input__thumb--color: #{$plc-black};

	//Role card
	--plc-role-card--bg: #{$plc-white-70};
	--plc-role-card--color: #{$plc-black};
	--plc-role-card__main--bg: #{$plc-black};
	--plc-role-card__main--color: #{$plc-white};

	// Score counter
	--plc-score-counter--color: #{$plc-black};

	//Section
	--plc-section__hr--bg: #{$plc-black};

	//Stepper
	--plc-stepper__step--curr--bg: #{$plc-dark-yellow};
	--plc-stepper__step--bg: #{$plc-black};
	--plc-stepper__step__name--color: #{$plc-black};

	// Tabs
	--plc-tabs__header__text--border-color: #{$plc-black};
	--plc-tabs__header__title--color: #{$plc-black};

	//Text input
	--plc-text-input--color: #{$plc-black};
	--plc-text-input__wrapper--bg: #{$plc-white};

	//Toggle button three
	--plc-toggle-button-three--color: #{$plc-black};
	--plc-toggle-button-three--color-rev: #{$plc-white};
	--plc-toggle-button-three--bc: #{$plc-carbon-grey};
	--plc-toggle-button-three--bg: #{$plc-carbon-grey};

	//Toolbar
	--plc-toolbar--bg: #{$plc-white-90};
	--plc-toolbar--mobile--bg: #{$plc-white-90};
	--plc-toolbar--color: #{$plc-black};
	--plc-toolbar__icon-filter: #{$plc-black-filter};
	--plc-toolbar__icon-filter--hover: #{$plc-dark-yellow-filter};
	--plc-menu--bg: #{$plc-dark-yellow};
	--plc-menu--color: #{$plc-white};
	--plc-menu__options--bg: #{$plc-dark-yellow};
	--plc-menu__options--color: #{$plc-white};
	--plc-menu__options__option--bg: #{$plc-black};
	--plc-menu__options__option--color: #{$plc-white};
	--plc-menu__options__option__icon--filter: #{$plc-white-filter};
	--plc-menu__options__option__icon--filter--hover: #{$plc-white-filter};
	--plc-side-menu-scroll-track--bg: #{$plc-dark-yellow};
	--plc-side-menu-scroll-thumb--bg: #{$plc-black};
	--plc-side-menu__footer__icons__icon--filter: #{$plc-black-filter};

	//User Game Result
	--plc-user-game-result--bg: #{$plc-white};
	--plc-user-game-result--color: #{$plc-black};
	--plc-user-game-result__name--bg: #{$plc-black};
	--plc-user-game-result__name--color: #{$plc-white};
	--plc-user-game-result__splitter--border-color: #{$plc-black};
	--plc-user-game-result__rival__img--placeholder--filter: #{$plc-black-filter};

	//Validation Message
	--plc-validation-messages--color: #{$plc-black};

	//Wizard
	--plc-wizard__hole--bs: #{$plc-carbon-grey-70};
	--plc-tooltip--color: #{$plc-black};
	--plc-tooltip__content__text__anchor--color: #{$plc-dark-blue};
	--plc-tooltip__content__text__img--filter: #{$plc-black-filter};
}

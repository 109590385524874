.plc-theme-switcher {
	align-items: center;
	display: grid;
	grid-gap: $plc-gap--s;

	&--vertical {
		grid-template-areas: 'sun' 'label' 'moon';
		grid-template-columns: 34px;
		grid-template-rows: 34px 60px 34px;
		height: 134px;
		width: 34px;
	}

	&--horizontal {
		grid-template-areas: 'sun label moon';
		grid-template-columns: 34px 60px 34px;
		grid-template-rows: 34px;
		height: 34px;
		width: 134px;
	}

	&__sun {
		filter: $plc-dark-yellow-filter;
		grid-area: sun;
		opacity: 0.4;
		transition: $plc-duration--l;
		width: 100%;

		&--choosen {
			opacity: 1;
		}
	}

	&__moon {
		filter: $plc-dark-blue-filter;
		grid-area: moon;
		opacity: 0.4;
		transition: $plc-duration--l;
		width: 80%;

		&--choosen {
			opacity: 1;
		}
	}

	&__label {
		display: inline-block;
		position: relative;

		&--vertical {
			height: 60px;
			width: 34px;
		}

		&--horizontal {
			height: 34px;
			width: 60px;
		}

		/* Hide default HTML checkbox */
		&__checkbox {
			height: 0;
			opacity: 0;
			width: 0;

			&:checked {
				&.plc-theme-switcher__label__slider {
					background-color: $plc-dark-blue;
				}
			}

			&:focus {
				&.plc-theme-switcher__label__slider {
					box-shadow: 0 0 1px $plc-dark-blue;
				}
			}

			&:checked + .plc-theme-switcher__label__slider--vertical::before {
				transform: translateY(0);
			}

			&:checked + .plc-theme-switcher__label__slider--horizontal::before {
				transform: translateX(26px);
			}
		}

		&__slider {
			background-color: $plc-dark-yellow;
			border-radius: $plc-border-radius--xl;
			inset: 0;
			cursor: pointer;
			position: absolute;
			transition: $plc-duration--l;
		}

		&__slider::before {
			background-color: $plc-carbon-grey;
			border-radius: $plc-border-radius--round;
			bottom: 4px;
			content: '';
			height: 26px;
			left: 4px;
			position: absolute;
			transition: $plc-duration--l;
			width: 26px;
		}

		&__slider--vertical::before {
			transform: translateY(-26px);
		}

		&__slider--horizontal::before {
			transform: translateX(0);
		}
	}
}

.plc-theme-switcher2 {
	height: 34px;
	overflow: hidden;
	position: relative;
	width: 50px;

	& > * {
		animation: hide $plc-duration--xxxl ease-in;
		animation-direction: normal;
		animation-fill-mode: forwards;
		cursor: pointer;
		filter: $plc-light-grey-filter;
		height: inherit;
		left: 0;
		position: absolute;
		top: 0;
		transform-origin: 50% 250%;
		transition: $plc-duration--xl;
	}

	&__sun {
		&--selected {
			animation: rise $plc-duration--xxxl linear;
			animation-fill-mode: forwards;
			filter: $plc-dark-yellow-filter;
			transition-delay: $plc-duration--xl;
		}
	}

	&__moon {
		height: 80%;

		&--selected {
			animation: rise $plc-duration--xxxl linear;
			animation-fill-mode: forwards;
			filter: $plc-dark-blue-filter;
			transition-delay: $plc-duration--xl;
		}
	}
}

@keyframes hide {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(180deg);
	}
}

@keyframes rise {
	0% {
		transform: rotate(300deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

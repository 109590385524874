@use 'sass:math';

/// Gestor Responsive
/// @access public
/// @param String $plc-breakpoint
/// @requires $plc-breakpoints
/// @deprecated
@mixin respond-to($plc-breakpoint) {
	$plc-raw-query: map-get($plc-breakpoints, $plc-breakpoint);

	@if $plc-raw-query {
		$plc-query: if(
			type-of($plc-raw-query) == 'string',
			unquote($plc-raw-query),
			inspect($plc-raw-query)
		);

		@media #{$plc-query} {
			@content;
		}
	} @else {
		@error 'No value found for `#{$plc-breakpoint}`. Please, ensure value is defined on map: `$plc-breakpoints`.';
	}
}

@mixin rounded-hex(
	$plc-main-radius: 30%,
	$plc-rounding-radius: 10%,
	$plc-rotated: false,
	$plc-precision: 20
) {
	$plc-n: 5;
	$plc-central-angle: 360deg / $plc-n;
	$plc-angle: ($plc-n - 2) * 180deg / $plc-n;
	$plc-max-var-angle: 2 * (90deg - $plc-angle * 0.5);
	$plc-precision: 5;
	$plc-unit-var-angle: $plc-max-var-angle / $plc-precision;

	$plc-r-diff: $plc-main-radius + $plc-rounding-radius;

	$plc-points: ();

	@for $plc-i from 0 to $plc-n {
		$plc-vertex-angle: $plc-i *
			$plc-central-angle +
			if($plc-rotated, -180deg, 0deg);
		$plc-vertex-x: 50% + $plc-r-diff * cos($plc-vertex-angle);
		$plc-vertex-y: 50% + $plc-r-diff * sin($plc-vertex-angle);

		@for $plc-j from 0 through $plc-precision {
			$plc-curr-angle: $plc-vertex-angle +
				($plc-j - 0.5 * $plc-precision) *
				$plc-unit-var-angle;
			$plc-x: $plc-vertex-x + $plc-rounding-radius * cos($plc-curr-angle);
			$plc-y: $plc-vertex-y + $plc-rounding-radius * sin($plc-curr-angle);

			$plc-points: append($plc-points, ($plc-x, $plc-y));
		}
	}

	clip-path: polygon($plc-points);
}

.plc-action-buttons {
	display: flex;
	gap: $plc-gap--m;
	justify-content: flex-end;
	margin-top: $plc-gap--huge;

	> :first-child {
		justify-self: start;
		margin-right: auto;
	}
}

.plc-action-buttons__spacer {
	width: 50px;
}

// ============= Mobile styles =============
@include respond-to('small') {
	.plc-action-buttons {
		flex-direction: column;
		margin-top: $plc-gap--xxl;

		> :first-child {
			justify-self: initial;
			margin-right: 0;
		}

		> * {
			width: 100%;
		}
	}

	.plc-action-buttons__back-button {
		display: none;
	}
}

.plc-alphabet-soup-result__soups-row {
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--xxl;
	overflow: scroll;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		display: none;
	}
}

.plc-alphabet-soup--result {
	.plc-alphabet-soup__char {
		font-size: $plc-font-size--l;
		width: 40px;
	}
}

.plc-alphabet-soup--result:first-child {
	margin-bottom: $plc-gap--xxl;
}

@include respond-to('small') {
	.plc-alphabet-soup--result {
		.plc-alphabet-soup__char {
			font-size: $plc-font-size--m;
			width: 30px;
		}
	}
}

.plc-label {
	color: var(--plc-label--color);
	display: grid;
	transition: $plc-duration--m;
	transition-property: color;
	width: fit-content;
}

.plc-label--xs {
	font-size: $plc-font-size--xs;
}

.plc-label--s {
	font-size: $plc-font-size--s;
}

.plc-label--m {
	font-size: $plc-font-size--m;
}

.plc-label--l {
	font-size: $plc-font-size--m * 1.25;
}

.plc-label--xl {
	font-size: $plc-font-size--l;
}

.plc-label--xxl {
	font-size: $plc-font-size--xl;
}

.plc-label--xxxl {
	font-size: $plc-font-size--xxl;
}

.plc-label--masive {
	font-size: $plc-font-size--xxxl;
}

.plc-label--reversed-colors {
	color: var(--plc-label--color-reversed);
}

.plc-label__title {
	font-family: $plc-font-family--lobster;
}

.plc-label__text {
	font-family: $plc-font-family--lucida;
}

.plc-label__text--hidden {
	display: none;
}

.plc-label--vertical {
	grid-auto-flow: row;
	grid-row-gap: $plc-gap--m;
	justify-content: center;
}

.plc-label--horizontal {
	grid-auto-flow: column;
	grid-column-gap: $plc-gap--m;
}

.plc-label--ltr {
	direction: ltr;
}

.plc-label--rtl {
	direction: rtl;
}

.plc-label--center {
	text-align: center;
}

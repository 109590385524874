.plc-role-picker__header {
	align-items: center;
	background-color: var(--plc-panel--bg);
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--m;
	justify-content: center;
	justify-self: center;
	padding: $plc-gap--m $plc-gap--xl;
}

.plc-role-picker__roles {
	display: grid;
	grid-gap: $plc-gap--xl;
	overflow: scroll;
	scroll-behavior: smooth;
}

.plc-role-picker__roles--2p {
	grid-template-columns: repeat(2, auto);
}

.plc-role-picker__roles--3p {
	grid-template-columns: repeat(3, auto);
}

.plc-role-picker__roles--5p,
.plc-role-picker__roles--7p {
	grid-template-columns: repeat(4, auto);
	grid-template-rows: repeat(2, auto);
}

.plc-role-picker__roles::-webkit-scrollbar {
	display: none;
}

.plc-role-picker__roles__presenter {
	grid-column: 2 / span 2;
	justify-self: center;
}

.plc-role-picker__roles__last-guest {
	grid-column: 3 / span 2;
	justify-self: end;
}

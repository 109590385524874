.plc-icon-badge {
	display: grid;
	height: 50px;
	justify-content: end;
	position: relative;
	width: 60px;

	&--small {
		height: 40px;
		width: 50px;
	}

	&--medium {
		height: 50px;
		width: 60px;
	}

	&--big {
		height: 60px;
		width: 70px;
	}

	&__badge {
		background-color: $plc-dark-green;
		border-radius: $plc-border-radius--l;
		box-sizing: border-box;
		color: $plc-white;
		font-family: $plc-font-family--lucida;
		font-size: $plc-font-size--m;
		left: 0;
		min-width: 30px;
		padding: $plc-gap--s;
		position: absolute;
		text-align: center;
		width: min-content;

		&--small {
			bottom: 10px;
		}

		&--medium {
			bottom: 20px;
		}

		&--big {
			bottom: 30px;
		}
	}

	&__icon {
		filter: var(--plc-icon-badge--filter);
		transition: $plc-duration--m;
		transition-property: filter;

		&--small {
			width: 40px;
		}

		&--medium {
			width: 50px;
		}

		&--big {
			width: 60px;
		}
	}
}

.plc-tabs {
	display: grid;
	grid-row-gap: $plc-gap--m;
	max-height: none !important;
}

.plc-tabs__header {
	display: grid;
	grid-auto-flow: column;
	grid-gap: $plc-gap--xl;
	height: 60px;
	justify-content: center;
}

.plc-tabs__header__tab {
	align-items: center;
	display: grid;
	grid-gap: $plc-gap--s;
	grid-template-columns: repeat(2, auto);
	height: inherit;
}

.plc-tabs__header__tab:hover {
	cursor: pointer;
}

.plc-tabs__header__tab--selected {
	border-bottom: $plc-border-width--m solid
		var(--plc-tabs__header__text--border-color);
}

.plc-tabs__header__tab__title {
	color: var(--plc-tabs__header__title--color);
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
}

.plc-tabs__content {
	max-height: none !important;
}

// ==== Tab Component ====

.plc-tab {
	display: grid;
	grid-row-gap: $plc-gap--l;
	justify-items: center;
	max-height: none !important;
}

.plc-tab--hidden {
	display: none;
}

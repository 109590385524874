.plc-players-overview {
	container-type: inline-size;
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(3, 1fr);
}

.plc-players-overview__presenter {
	align-self: center;
	display: grid;
	justify-items: center;
}

.plc-players-overview__team {
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-auto-flow: row;
	grid-column-gap: $plc-gap--m;
	grid-template-columns: repeat(2, 1fr);
	justify-items: center;
}

.plc-players-overview__team--forzed-one-col {
	grid-row-gap: $plc-gap--m;
	grid-template-columns: 1fr;
}

.plc-players-overview__team:first-child {
	background-color: $plc-dark-blue-70;
}

.plc-players-overview__team:last-child {
	background-color: $plc-dark-orange-70;
}

.plc-players-overview__team > * {
	display: grid;
	justify-items: center;
}

.plc-players-overview__team__member {
	plc-avatar {
		display: grid !important;
		max-width: 150px !important;
	}
}

.plc-players-overview__team__member:first-of-type {
	grid-column: 1/-1;
}

.plc-players-overview__team__title {
	grid-column: 1/-1;
	justify-self: center;
	margin-bottom: $plc-gap--m;
}

.plc-players-overview--two-col {
	grid-column-gap: $plc-gap--m;
	grid-template-columns: repeat(2, 1fr);
}

//======= Small Parent Styles =========
@container (max-width: 540px) {
	.plc-players-overview__team {
		grid-row-gap: $plc-gap--m;
		grid-template-columns: 1fr;
	}
}

.plc-toast {
	display: grid;
	grid-auto-flow: row;
	grid-row-gap: $plc-gap--m;
	position: absolute;
	right: calc(100% - 420px);
	top: 20px;
	width: 400px;
}

.plc-toast__message {
	animation-duration: $plc-duration--xl;
	animation-fill-mode: both;
	animation-name: slide-in-down;
	border-radius: $plc-border-radius--m;
	color: $plc-white;
	display: grid;
	grid-auto-flow: row;
	grid-row-gap: $plc-gap--m;
	padding: $plc-gap--l;
	position: relative;

	plc-icon {
		position: absolute;
		right: 15px;
		top: 15px;
	}
}

.plc-toast__message--info {
	background-color: $plc-dark-green;
}

.plc-toast__message--error {
	background-color: $plc-dark-red;
}

.plc-toast__message--warning {
	background-color: $plc-dark-yellow;
}

.plc-toast__message__title {
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--l;
}

.plc-toast__message__text {
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	text-align: justify;
}

// ============= Mobile styles =============
@include respond-to('small') {
	.plc-toast {
		left: 10px;
		top: 10px;
		width: calc(100% - 20px);
	}
}

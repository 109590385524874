.plc-toggle-button-three {
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-auto-flow: column;
	height: 60px;
	width: fit-content;
}

.plc-toggle-button-three--disabled {
	filter: brightness(0.7);
}

.plc-toggle-button-three--disabled:hover {
	.plc-toggle-button-three__option:hover {
		background: initial;
		color: var(--plc-toggle-button-three--color);
		cursor: not-allowed !important;
	}

	.plc-toggle-button-three__option--selected {
		background: var(--plc-toggle-button-three--bg) !important;
		color: var(--plc-toggle-button-three--color-rev) !important;
		cursor: not-allowed !important;
	}
}

.plc-toggle-button-three__option {
	border: solid 2px var(--plc-toggle-button-three--bc);
	border-collapse: collapse;
	color: var(--plc-toggle-button-three--color);
	display: grid;
	grid-auto-flow: column;
	transition-duration: $plc-duration--m;
	transition-property: background, color;
}

.plc-toggle-button-three__option:hover,
.plc-toggle-button-three__option--selected {
	background: var(--plc-toggle-button-three--bg);
	color: var(--plc-toggle-button-three--color-rev);
	cursor: pointer;
}

.plc-toggle-button-three__option:first-child {
	border-bottom-left-radius: $plc-border-radius--m;
	border-top-left-radius: $plc-border-radius--m;
}

.plc-toggle-button-three__option:last-child {
	border-bottom-right-radius: $plc-border-radius--m;
	border-top-right-radius: $plc-border-radius--m;
}

.plc-toggle-button-three__option__item {
	align-content: center;
	display: grid;
	justify-content: center;
	padding: $plc-gap--s $plc-gap--m;
}

.plc-toggle-button-three__option__item__img {
	border-radius: $plc-border-radius--round;
	height: 40px;
}

@include respond-to('small') {
	.plc-toggle-button-three {
		height: 45px;
	}

	.plc-toggle-button-three__option__item {
		padding: $plc-gap--xs $plc-gap--s;
	}

	.plc-toggle-button-three__option__item__img {
		height: 25px;
	}
}

.plc-avatar {
	aspect-ratio: 1/1;
	display: contents;
	object-fit: cover;
}

.plc-avatar--animated {
	animation: zoom-in $plc-duration--m ease-in;
}

.plc-avatar--zoomed {
	transform: scale(1.05);
}

.plc-avatar__img {
	max-height: 100% !important;
	object-fit: cover;
	width: 100%;
}

.plc-avatar__img--square {
	border-radius: $plc-border-radius--m;
}

.plc-avatar__img--circle {
	border-radius: $plc-border-radius--round;
}

.plc-avatar__img--no-src {
	filter: var(avatar__img--no-src--filter);
}

.plc-avatar__img--no-src-reversed {
	filter: var(avatar__img--no-src-reversed--filter);
}

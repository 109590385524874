.plc-validation-messages {
	color: var(--plc-text-input--color);
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-auto-flow: row;
	grid-gap: $plc-gap--s;
	margin-top: $plc-gap--m;
	overflow: hidden;
	transition: color $plc-duration--m;
}

.plc-validation-messages--hide-mode {
	display: none;
}

.plc-validation-messages__validation {
	align-items: center;
	color: var(--plc-validation-messages--color);
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: min-content 1fr;
}

.plc-validation-messages__validation__icon {
	animation-duration: $plc-duration--xl;
	animation-name: bounce;
}

/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable import-notation */
@import 'base/animations';
@import 'base/typography';
@import 'base/reset';

@import 'utils/helpers';
@import 'utils/variables';
@import 'utils/variables/borders';
@import 'utils/variables/colors';
@import 'utils/variables/durations';
@import 'utils/variables/filters';
@import 'utils/variables/fonts';
@import 'utils/variables/gaps';
@import 'utils/mixins';

@import 'themes/light';
@import 'themes/dark';

@import 'components/common/avatar';
@import 'components/common/button';
@import 'components/common/disclaimer';
@import 'components/common/dialog';
@import 'components/common/flag-picker';
@import 'components/common/icon';
@import 'components/common/icon-badge';
@import 'components/common/label';
@import 'components/common/link';
@import 'components/common/loader';
@import 'components/common/progress-bar';
@import 'components/common/stepper';
@import 'components/common/theme-switcher';
@import 'components/common/toast';
@import 'components/common/toggle-button-three';
@import 'components/common/wizard';

@import 'components/forms/checkbox';
@import 'components/forms/check-panel-group';
@import 'components/forms/file-picker';
@import 'components/forms/pin-code';
@import 'components/forms/number-input';
@import 'components/forms/text-input';
@import 'components/forms/radio-buttons';
@import 'components/forms/range-input';
@import 'components/forms/validation-messages';

@import 'components/game/alphabet-soup/alphabet-soup';
@import 'components/game/bagel/bagel-char';
@import 'components/game/bagel/bagel-dial';
@import 'components/game/bagel/bagels';
@import 'components/game/commons/clue-timer';
@import 'components/game/commons/game-row';
@import 'components/game/commons/phase-score-counter';
@import 'components/game/commons/players-overview';
@import 'components/game/commons/role-card';
@import 'components/game/commons/score-counter';
@import 'components/game/commons/toggle-button';
@import 'components/game/commons/webcam';
@import 'components/game/memory-phase/flipper-cell';
@import 'components/game/memory-phase/flipper-grid';

@import 'components/layout/background-screen';
@import 'components/layout/box';
@import 'components/layout/footer';
@import 'components/layout/modal';
@import 'components/layout/panel';
@import 'components/layout/raising-panel';
@import 'components/layout/section';
@import 'components/layout/tabs';
@import 'components/layout/toolbar-desktop';
@import 'components/layout/toolbar-mobile';

@import 'components/profile/donut-chart';
@import 'components/profile/image-cropper';
@import 'components/profile/user-game-result';

@import 'layout/action-buttons';
@import 'layout/forms';
@import 'layout/grid';

@import 'pages/alphabet-soup-phase/alphabet-soup-dashboard';
@import 'pages/alphabet-soup-phase/alphabet-soup-player';
@import 'pages/alphabet-soup-phase/alphabet-soup-preview';
@import 'pages/alphabet-soup-phase/alphabet-soup-result';
@import 'pages/alphabet-soup-phase/alphabet-soup-viewer';
@import 'pages/bagel-phase/bagels-dashboard';
@import 'pages/bagel-phase/bagels-player';
@import 'pages/bagel-phase/bagels-preview';
@import 'pages/bagel-phase/bagels-result';
@import 'pages/bagel-phase/bagels-viewer';
@import 'pages/home';
@import 'pages/memory-phase/memory-dashboard';
@import 'pages/memory-phase/memory-preview';
@import 'pages/memory-phase/memory-player';
@import 'pages/memory-phase/memory-result';
@import 'pages/memory-phase/memory-viewer';
@import 'pages/new-game';
@import 'pages/search-game';
@import 'pages/song-phase/song-dashboard';
@import 'pages/song-phase/song-result';
@import 'pages/song-phase/song-player-viewer';
@import 'pages/song-phase/song-preview';
@import 'pages/role-picker';
@import 'pages/tutorial';
@import 'pages/user/avatar-edit';
@import 'pages/user/profile';
@import 'pages/user/profile-edit';

@import 'shame';

.plc-donut-chart {
	display: grid;
	grid-auto-flow: row;
	grid-gap: $plc-gap--l;
	justify-items: center;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--s;
	color: var(--plc-donut-chart--color);
	transition: $plc-duration--m;
	transition-property: color, background-color;
	text-wrap: nowrap;
	background-color: var(--plc-donut-chart--bg);
	padding: $plc-gap--m;
	border-radius: $plc-border-radius--m;
	overflow: hidden;

	.plc-donut-chart__legend {
		align-items: center;
		height: min-content;
		display: grid;
		grid-auto-flow: column;
		grid-gap: $plc-gap--m;
		padding: $plc-gap--m;
		background-color: var(--plc-donut-chart__legend--bg);
		border-radius: $plc-border-radius--s;
		width: calc(100% - ($plc-gap--m * 2));
		overflow: scroll;
	}

	.plc-donut-chart__legend::-webkit-scrollbar {
		display: none;
	}

	.plc-donut-chart__legend::-webkit-scrollbar:horizontal {
		display: none;
	}

	.plc-donut-chart__legend__item {
		height: 25px;
		width: 25px;
		border-radius: 5px;
		cursor: pointer;
	}

	.plc-donut-chart__legend__item--selected {
		transform: scale(1.1);
	}

	.plc-donut-chart__legend__text {
		cursor: pointer;
	}

	.plc-donut-chart__legend__text--selected {
		font-weight: bolder;
	}
}

.plc-toolbar-mobile {
	align-items: center;
	background-color: var(--plc-toolbar--bg);
	color: var(--plc-toolbar--color);
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-template-rows: min-content 1fr min-content;
	height: calc(100% - 20px);
	padding: $plc-gap--m;
	position: relative;
	width: 30px;
}

.plc-toolbar-mobile--toggled {
	height: 100%;
	padding: 0;
	width: 100%;
}
// ===========================================

.plc-side-menu {
	background-color: var(--plc-toolbar--mobile--bg);
	box-sizing: border-box;
	color: black;
	color: var(--plc-toolbar--color);
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: scroll;
	padding: $plc-gap--m;
	width: 320px;
	z-index: $plc-menu__options--z-index;
}

.plc-side-menu::-webkit-scrollbar {
	display: none;
}

.plc-side-menu__title {
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--l;
	margin: $plc-gap--m 0;
	text-align: center;
}

.plc-side-menu__user-wrapper {
	border-bottom: solid $plc-border-width--m var(--plc-toolbar--color);
	display: grid;
	grid-auto-flow: row;
	grid-gap: $plc-gap--m;
	justify-items: center;
	margin-bottom: $plc-gap--m;
	padding: $plc-gap--m;
}

.plc-side-menu__user-wrapper__avatar {
	display: grid;
	width: 60%;
}

.plc-side-menu__user-wrapper__name {
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--l;
}

.plc-side-menu__option {
	align-items: center;
	border-radius: $plc-border-radius--m;
	cursor: pointer;
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: min-content auto;
	height: 20px;
	padding: $plc-gap--m;
}

.plc-side-menu__option:hover:active {
	background: var(--plc-menu__options__option--bg);
	color: var(--plc-menu__options__option--color);

	.plc-side-menu__option__icon {
		filter: var(--plc-menu__options__option__icon--filter--hover);
	}
}

.plc-side-menu__volume {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: min-content auto;
	margin-top: $plc-gap--xl;
	padding: $plc-gap--m;
	width: 90%;
}

.plc-side-menu__spacer {
	flex-grow: 1;
}

.plc-side-menu__footer {
	border-top: solid $plc-border-width--m var(--plc-toolbar--color);
	display: grid;
	grid-auto-columns: auto;
	grid-row-gap: $plc-gap--m;
	margin-top: $plc-gap--m;
	padding: $plc-gap--m;
	text-align: center;
}

.plc-side-menu__heart {
	color: $plc-dark-red;
}

.plc-side-menu__footer__icons {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(3, min-content);
	justify-content: center;
	margin-top: $plc-gap--m;
}
//======= Small Parent Styles =========
@container (max-width: 768px) {
	.plc-toolbar-mobile {
		grid-template-columns: min-content 1fr min-content;
		grid-template-rows: 1fr;
		height: 30px;
		justify-items: center;
		width: 100%;
	}

	.plc-toolbar-mobile--toggled {
		height: 100%;
	}
}

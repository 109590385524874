.plc-clue-timer {
	align-items: center;
	color: $plc-white;
	display: grid;
	font-family: $plc-font-family--lucida;
	grid-template-columns: 1fr auto;
	position: relative;
	transition: padding $plc-duration--m ease-in;
}

.plc-clue-timer--with-hits {
	padding-top: $plc-gap--xxl;
}

.plc-clue-timer__clue {
	align-content: center;
	animation: stretch-horizontal $plc-duration--xl ease-in-out;
	border-radius: $plc-border-radius--xl 0 0 $plc-border-radius--xl;
	display: grid;
	font-size: $plc-font-size--m;
	height: 50px;
	justify-content: center;
	justify-self: end;
	overflow: hidden;
	position: relative;
	right: -$plc-gap--m;
	width: calc(100% + #{$plc-gap--m});
}

.plc-clue-timer__clue__text--default {
	white-space: nowrap;
}

.plc-clue-timer__clue__text--animated {
	animation: infinite 8s ease-in-out slide-in-left;
	left: $plc-gap--m;
	position: absolute;
	top: 30%;
	white-space: nowrap;
}

.plc-clue-timer__hits {
	animation: slide-in-up $plc-duration--xl ease-in-out;
	border-radius: $plc-border-radius--round $plc-border-radius--round 0 0;
	bottom: 64px;
	box-sizing: border-box;
	font-family: $plc-font-family--lobster;
	padding: $plc-gap--m $plc-gap--l;
	position: absolute;
	right: 18px;
	width: min-content;
}

.plc-clue-timer__timer {
	align-content: center;
	border: $plc-border-width--s solid
		var(--plc-clue-timer__timer--border-color);
	border-radius: $plc-border-radius--round;
	display: grid;
	font-family: $plc-font-family--lobster;
	font-size: $plc-font-size--l;
	height: 70px;
	justify-content: center;
	position: relative;
	width: 70px;
}

.plc-clue-timer--blue {
	.plc-clue-timer__clue {
		background-color: $plc-dark-blue;
	}

	.plc-clue-timer__timer {
		background-color: $plc-dark-blue;
	}

	.plc-clue-timer__hits {
		background-color: $plc-dark-blue;
	}
}

.plc-clue-timer--orange {
	.plc-clue-timer__clue {
		background-color: $plc-dark-orange;
	}

	.plc-clue-timer__timer {
		background-color: $plc-dark-orange;
	}

	.plc-clue-timer__hits {
		background-color: $plc-dark-orange;
	}
}

.plc-range-input {
	width: 100%;
}

.plc-range-input__slider {
	appearance: none;
	background: var(--plc-range-input--bg-2);
	background-image: linear-gradient(
		var(--plc-range-input--bg-1),
		var(--plc-range-input--bg-1)
	);
	background-repeat: no-repeat;
	border-radius: $plc-border-radius--s;
	height: 7px;
	width: 100%;
}

.plc-range-input__slider::-webkit-slider-thumb {
	appearance: none;
	background: var(--plc-range-input__thumb--color);
	background: radial-gradient(
		circle at center,
		transparent 25%,
		var(--plc-range-input__thumb--color) 25.5%
	);
	border-radius: $plc-border-radius--round;
	cursor: pointer;
	height: 25px;
	width: 25px;
	// clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)
	// transform: rotate(90deg)
	// @include rounded-hex($plc-rotated: true)
}

.plc-bagel-dial {
	color: $plc-white;
	display: grid;
	font-family: $plc-font-family--lucida;
	font-size: $plc-font-size--m;
	grid-template-areas: 'hits' 'time';
	grid-template-rows: repeat(2, auto);
	height: 106px;
	justify-items: center;
	width: min-content;
}

.plc-bagel-dial__hits {
	animation: slide-in-up $plc-duration--xl ease-in-out;
	border-radius: $plc-border-radius--round $plc-border-radius--round 0 0;
	box-sizing: border-box;
	padding: $plc-gap--m $plc-gap--l;
	width: min-content;
}

.plc-bagel-dial__time {
	align-items: center;
	animation: zoom-in $plc-duration--m ease-in-out;
	border: $plc-border-width--s solid var(--plc-bagel-dial--border-color);
	border-radius: $plc-border-radius--round;
	box-sizing: border-box;
	display: grid;
	font-size: $plc-font-size--l;
	height: 70px;
	justify-items: center;
	padding: $plc-gap--m;
	transform: translateY(-8px);
	width: 70px;
}

.plc-bagel-dial--blue {
	background-color: $plc-dark-blue;
}

.plc-bagel-dial--orange {
	background-color: $plc-dark-orange;
}

.plc-bagel {
	align-items: center;
	border-radius: $plc-border-radius--round;
	display: grid;
	height: 400px;
	justify-items: center;
	margin: $plc-gap--xl;
	position: relative;
	width: 400px;

	&__avatar {
		border-radius: $plc-border-radius--round;
		width: inherit;
	}

	&__no-avatar {
		align-content: center;
		display: grid;
		filter: var(--plc-bagel__no-avatar--filter);
	}

	& > * {
		position: absolute;
	}
}

// ============= Mobile styles =============
@include respond-to('small') {
	.plc-bagel {
		height: 280px;
		width: 280px;
	}
}

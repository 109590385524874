.plc-soups-player__cam {
	border-radius: $plc-border-radius--m;
}

.plc-soups-player__cam--turn {
	box-shadow: 0 0 10px 5px;
}

.plc-soups-player__cam--blue-team {
	color: $plc-dark-blue;
}

.plc-soups-player__cam--orange-team {
	color: $plc-dark-orange;
}

.plc-alphabet-soup--player {
	justify-self: center;
}

@include respond-to('small') {
	.plc-soups-player__cam--turn {
		align-self: center;
		height: 94%;
		justify-self: center;
		width: 94%;
	}

	.plc-soups-player__box {
		.plc-box__main {
			grid-template-rows: 1fr 2fr;
		}
	}
}

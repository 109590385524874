.plc-dialog-wrapper {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.plc-dialog-wrapper__background-screen {
	display: grid;
}

.plc-dialog {
	align-self: center;
	backdrop-filter: blur(7px);
	background-color: var(--plc-dialog--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	box-shadow: 0 0 0 200vmax var(--plc-dialog--bs);
	display: grid;
	grid-gap: $plc-gap--l;
	grid-template-rows: auto 1fr;
	justify-self: center;
	max-height: 75vh;
	min-width: 340px;
	overflow: hidden;
	padding: $plc-gap--m;
}

.plc-dialog__header {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: 30px 1fr 30px;
	justify-items: center;
}

.plc-dialog__main {
	display: grid;
	grid-auto-flow: row;
	grid-gap: $plc-gap--m;
	overflow: scroll;
	padding: $plc-gap--m;
}

.plc-dialog__main::-webkit-scrollbar {
	display: none;
}

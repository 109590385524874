.plc-progress-bar {
	background: var(--plc-progress-bar--bg);
	border-radius: $plc-border-radius--m;
	box-sizing: border-box;
	height: 30px;
	padding: $plc-gap--s;
	width: clamp(200px, 100%, 300px);
}

.plc-progress-bar__value {
	background: repeating-linear-gradient(
		45deg,
		$plc-dark-green 0,
		$plc-dark-green 20px,
		$plc-light-green 20px,
		$plc-light-green 40px
	);
	border-radius: $plc-border-radius--s;
	height: stretch;
}

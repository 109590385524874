.plc-game-row {
	background: var(--plc-games-row--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	container-type: inline-size;
	padding: $plc-gap--m;
}

.plc-game-row__row-top {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: 1fr auto;
	justify-items: center;
}

.plc-game-row__row-bottom {
	display: grid;
	grid-template-columns: repeat(3, auto);
	justify-content: space-between;
}

.plc-game-row__row-top__phases {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(4, auto);
	grid-template-rows: repeat(2, auto);
	justify-content: center;
}

.plc-game-row__row-top__phases--bagel-only {
	grid-template-columns: auto;
	grid-template-rows: repeat(2, auto);
}

.plc-game-row__row-top__phases > plc-label {
	grid-column: 1/-1;
	justify-self: center;
}

.plc-game-row__row-bottom__icons {
	align-content: space-between;
	display: grid;
}

.plc-game-row__row-bottom__players {
	background: var(--plc-games-row--bg);
	border: $plc-border-width--s solid $plc-white-20;
	border-radius: $plc-border-radius--m;
	display: grid;
	grid-gap: $plc-gap--s;
	grid-template-rows: min-content auto;
	padding: $plc-gap--s;
}

.plc-game-row__row-bottom__players__header {
	display: grid;
	justify-content: center;
}

.plc-game-row__row-bottom__players__main {
	place-items: center center;
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: repeat(2, auto);
}

.plc-game-row__row-bottom__players__main--first {
	grid-column: span 2;
	justify-self: center;
}

.plc-game-row__row-bottom__players__main--presenter {
	align-self: center;
	grid-row: 1 / -1;
}

.plc-game-row__row-bottom__buttons {
	align-content: space-between;
	display: grid;
	grid-gap: $plc-gap--m;
}
//======= Small Parent Styles =========
@container (max-width: 540px) {
	.plc-game-row__small {
		display: grid;
		grid-gap: $plc-gap--m;
	}

	.plc-game-row__big {
		display: none;
	}
}

//======= Big Parent Styles =========
@container (min-width: 541px) {
	.plc-game-row__small {
		display: none;
	}

	.plc-game-row__big {
		display: grid;
		grid-auto-flow: column;
		justify-content: space-between;
	}

	.plc-game-row__row-bottom__col-2 {
		align-self: center;
		display: grid;
		grid-gap: $plc-gap--m;
		justify-items: center;
	}

	.plc-game-row__row-bottom__players__main {
		grid-auto-flow: unset;
		grid-template-columns: repeat(7, auto);
	}

	.plc-game-row__row-bottom__players__main--first {
		grid-column: initial;
	}

	.plc-game-row__row-bottom__players__main--presenter {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
	}
}

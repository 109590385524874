.plc-bagels-viewer {
	&__content {
		display: grid;
		grid-auto-flow: column;
		grid-gap: $plc-gap--xxxl;

		&__footer {
			align-content: center;
			align-items: end;
			display: grid;
			grid-auto-flow: column;
			justify-content: space-between;
			margin-top: -$plc-gap--huge;
			width: 100%;
		}
	}

	.plc-tabs {
		display: none;
	}

	// ============= Mobile styles =============
}
@include respond-to('small') {
	.plc-bagels-viewer {
		&__content {
			display: none;

			&__footer {
				margin-top: -$plc-gap--xxl;
			}
		}

		.plc-tabs {
			display: grid;
		}
	}
}

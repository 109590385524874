.plc-tutorial__stages {
	display: grid;
	grid-gap: $plc-gap--m;
	grid-template-columns: repeat(3, 1fr);

	.plc-button {
		align-self: center;
	}

	.plc-icon {
		align-self: center;
	}
}

.plc-tutorial__stages__stage {
	height: max-content;
}

@include respond-to('small') {
	.plc-tutorial__stages {
		grid-template-columns: 1fr;
	}
}
